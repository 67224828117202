export { default as About } from './about';
export { default as PartnerWithUs } from './partner-with-us';
export { default as Home } from './home';
export { default as GetInsurance } from './get-insurance';
export { default as NotFound } from './not-found';
export { default as Partner } from './partners';
export { default as Venue } from './venues';
export { default as Copilot } from './products/copilot';
export { default as Acquisition } from './products/acquisition';
export { default as Distribution } from './products/distribute';
export { default as Survey } from './survey';
export { default as Weddings } from './weddings';
export { default as HomeView } from './homeview';

// Point of Sale
export { default as Pos } from './point-of-sale';