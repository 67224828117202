import React from "react";
import Main from "layouts/main";
import { ChatbotStoreProvider } from "context/chatbot-state";
import Hero from "./hero";
import Display from "./display";
// import Partners from './partners';
// import Features from './features';
// import Products from './products';
import JumboCardAlt from "../../blocks/JumboCardAlt";
import TwoColumnCard from "../../blocks/TwoColumnCard";

const HomeView = (): JSX.Element => {
  // const payload = `'/get_started'`;
  return (
    <ChatbotStoreProvider>
      <Main>
        <Hero />

        <Display />
        <JumboCardAlt title="title" text="text" />
        <TwoColumnCard title="title" text="text" />
        {/* <Features />
        <Partners />
        <Products /> */}
      </Main>
    </ChatbotStoreProvider>
  );
};

export default HomeView;
