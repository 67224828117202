import React, { useContext } from "react";
import { Button, ButtonProps } from "@mui/material";
import { ChatbotContextLatest } from "context/chatbot-state-latest";
import { scrollToTop } from "utils/helper-generic";

declare global {
  interface Window {
    sendPayload?: (payload: string) => void;
    sendMessage?: (message: string) => void;
    chatOpen?: () => void;
  }
}

interface Props {
  payload?: string;
  message?: string;
  children?: React.ReactNode;
}

const AskIndmenButtonLatest = ({
  children,
  payload,
  message,
  ...buttonProps
}: Props & ButtonProps): JSX.Element => {
  const { setAskIndemnActive } = useContext(ChatbotContextLatest);

  const handleAsk = () => {
    setAskIndemnActive(true);
    const finalPayload = payload?.startsWith("/") ? payload : `/${payload}`;

    if (payload && window.sendPayload) {
      window.sendPayload(finalPayload); // Call global sendPayload method
    }
    if (message && window.sendMessage) {
      window.sendMessage(message); // Call global sendMessage method
    }

    if (!message && !payload && window?.chatOpen) {
      window.chatOpen();
    }
    scrollToTop("indemn-webchat");
  };

  return (
    <Button onClick={handleAsk} {...buttonProps}>
      {children}
    </Button>
  );
};

export default AskIndmenButtonLatest;
