import React from 'react';
import { GridContainer, Container } from 'components';
import Feature from 'views/components/feature';
import { Typography, useTheme, Box } from '@mui/material';

const features = [
  { icon: '/images/icons/feature-ai.svg', title: 'Conversational Artificial Intelligence', content: 'Our tech-driven approach transforms the maze of insurance jargon into an easy, guided conversation where you call the shots.' },
  { icon: '/images/icons/feature-magic.svg', title: 'Answers to insurance questions', content: 'We strive to provide the answer to every insurance question you have. Our goal is to give you the magical power to summon insurance expertise at the stroke of a few keys.' },
  { icon: '/images/icons/feature-compliant.svg', title: 'Online quote, policy, and service', content: 'We build digital insurance experiences for the best products in the market and from the highest rated insurance carrier partners. One place to ask, quote, compare, and buy.' }
];

const Features = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" gap={8}>
      <Container>
        <Typography color='primary.900' sx={{ fontSize: { xs: typography['3xl'].fontSize, md: typography['5xl'].fontSize }, ...typography.extrabold }} textAlign="center">
          We’re making event insurance a conversation.
        </Typography>
      </Container>
      <Box display="grid" gridTemplateColumns={{ xs: '1f', md: '1fr 1fr 1fr' }} gap={{ xs: 4, xl: 0.5 }}>
        {features.map(feature => <Feature key={feature.title} {...feature} />)}
      </Box>
    </GridContainer>
  );
};

export default Features;
