import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Main from "layouts/main";
import { Box, useTheme } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { ChatbotStoreProvider } from "context/chatbot-state";
import { ChatbotStoreProviderLatest } from "context/chatbot-state-latest";
import { Image, GridContainer, MarkDown, Loading } from "components";
import {
  BotHostContainer,
  AskIndmenButton,
  Faq,
  BotHostContainerLatest,
  AskIndmenButtonLatest,
} from "views/components";
import { useFAQ } from "hooks/use-faq";
import NotFound from "views/not-found";
import { parseAdditionalSlots } from "utils/helper-generic";
import ChatLoader from "context/chatLoader";

export default function GetInsurance(): JSX.Element {
  const { slug } = useParams();
  const { typography, palette } = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [event, setEvent] = useState<Event | null>(null);
  const { faqs } = useFAQ(event?.faq_table, event?.faq_list);

  useEffect(() => {
    const fetchProductBySlug = async () => {
      setLoading(true);
      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/fetch-product-by-slug`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ slug }),
            }
          )
        ).json();

        setEvent(res.product);

        // update page title
        document.title = res.product.page_title;
        // update page description
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            res.product.page_meta_description
          );
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    fetchProductBySlug();
  }, [slug]);

  if (loading) {
    return <Loading loading={!event} />;
  }

  if (!event) {
    return <NotFound />;
  }

  const initialPayload = {
    package_config: event.package_config,
    mandate_liability: event.mandate_liability,
    mandate_cancellation: event.mandate_cancellation,
    path_type: "product",
    product: event.slug,
    cancellation_available: true,
    ...parseAdditionalSlots(event.additional_slots),
  };

  const payload = `/get_started${JSON.stringify(initialPayload)}`;

  if (event.bot === "wedding") {
    return (
      <ChatbotStoreProvider
        botType={
          process.env.REACT_APP_BOT_TYPE
            ? process.env.REACT_APP_BOT_TYPE
            : event.bot
        }
        initPayload={payload}
      >
        <Main>
          <Image
            src={event.hero_img}
            sx={{
              display: "flex",
              background: palette.background.directInsuranceHero,
              clipPath: "url(#myClip)",
              height: { xs: "70vh", md: "50vh", xl: "unset" },
            }}
            imageProps={{
              style: {
                objectFit: "cover",
                mixBlendMode: "overlay",
              },
            }}
          />

          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path
                  transform="scale(0.00069444444, 0.00202839756)"
                  d="M1440 0H0V438.385C493.657 513.754 891.5 507 1440 438.385V0Z"
                ></path>
              </clipPath>
            </defs>
          </svg>

          <Box
            sx={{
              background:
                "radial-gradient(68.79% 89.54% at 114.1% -18.98%, rgba(0, 99, 247, 0.20) 0%, rgba(85, 133, 255, 0.20) 50.52%, rgba(245, 249, 255, 0.20) 100%), linear-gradient(326deg, rgba(255, 255, 255, 0.88) 48.8%, rgba(220, 219, 254, 0.88) 99.98%)",
              mt: { xs: "-30vh", md: "-12vh" },
            }}
          >
            <GridContainer>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.32)",
                  background:
                    "linear-gradient(155deg, rgba(255, 255, 255, 0.94) 36.16%, rgba(255, 255, 255, 0.20) 85.22%)",
                  backdropFilter: "blur(32px)",
                  width: { xs: 1, md: 6 / 8, xl: 6 / 12 },
                  margin: "0 auto",
                  padding: { xs: "36px 16px", md: "36px 24px" },
                }}
              >
                <MarkDown
                  sx={{ ...typography["3xl"], ...typography.bold, mb: 2 }}
                  text={event.hero_headline}
                />

                <MarkDown
                  sx={{ ...typography.base, ...typography.regular, mb: 4.5 }}
                  text={event.hero_body}
                />

                <AskIndmenButton
                  variant="contained"
                  endIcon={<FeatherIcon icon="message-circle" />}
                  size="large"
                >
                  {event.hero_cta}
                </AskIndmenButton>
              </Box>
            </GridContainer>
            <BotHostContainer />
            <Faq faqs={faqs} />
            {/* <Pricing /> */}
          </Box>
        </Main>
      </ChatbotStoreProvider>
    );
  } else {
    return (
      <ChatbotStoreProviderLatest>
        <Main>
          <Image
            src={event.hero_img}
            sx={{
              display: "flex",
              background: palette.background.directInsuranceHero,
              clipPath: "url(#myClip)",
              height: { xs: "70vh", md: "50vh", xl: "unset" },
            }}
            imageProps={{
              style: {
                objectFit: "cover",
                mixBlendMode: "overlay",
              },
            }}
          />

          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path
                  transform="scale(0.00069444444, 0.00202839756)"
                  d="M1440 0H0V438.385C493.657 513.754 891.5 507 1440 438.385V0Z"
                ></path>
              </clipPath>
            </defs>
          </svg>

          <Box
            sx={{
              background:
                "radial-gradient(68.79% 89.54% at 114.1% -18.98%, rgba(0, 99, 247, 0.20) 0%, rgba(85, 133, 255, 0.20) 50.52%, rgba(245, 249, 255, 0.20) 100%), linear-gradient(326deg, rgba(255, 255, 255, 0.88) 48.8%, rgba(220, 219, 254, 0.88) 99.98%)",
              mt: { xs: "-30vh", md: "-12vh" },
            }}
          >
            <GridContainer>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.32)",
                  background:
                    "linear-gradient(155deg, rgba(255, 255, 255, 0.94) 36.16%, rgba(255, 255, 255, 0.20) 85.22%)",
                  backdropFilter: "blur(32px)",
                  width: { xs: 1, md: 6 / 8, xl: 6 / 12 },
                  margin: "0 auto",
                  padding: { xs: "36px 16px", md: "36px 24px" },
                }}
              >
                <MarkDown
                  sx={{ ...typography["3xl"], ...typography.bold, mb: 2 }}
                  text={event.hero_headline}
                />

                <MarkDown
                  sx={{ ...typography.base, ...typography.regular, mb: 4.5 }}
                  text={event.hero_body}
                />
                <AskIndmenButtonLatest
                  variant="contained"
                  endIcon={<FeatherIcon icon="message-circle" />}
                  size="large"
                >
                  {event.hero_cta}
                </AskIndmenButtonLatest>
              </Box>
            </GridContainer>
            <BotHostContainerLatest initialPayload={initialPayload} />
            <Faq faqs={faqs} />
            {/* <Pricing /> */}
          </Box>
          <ChatLoader />
        </Main>
      </ChatbotStoreProviderLatest>
    );
  }
}
