import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { Container, Image } from 'components';
import ExternalLink from './external-link';

interface PartnerProps {
  icon: string;
  name: string;
  description: string;
  size?: string; // TODO: need to use enum like 'small' | 'medium' | 'large'
  nameColor?: string;
  descriptionColor?: string;
  href?: string;
}

const Partner = ({
  icon,
  name,
  description,
  size = 'medium',
  nameColor = 'monochrome.gray.800',
  descriptionColor = 'monochrome.gray.600',
  href
}: PartnerProps
): JSX.Element => {
  const { typography } = useTheme();

  const iconWidth = 80;
  const iconSize = {
    large: {
      width: 1.5 * iconWidth,
      height: 1.5 * iconWidth
    },
    medium: {

    }
  };

  return (
    <Container display="grid" gridTemplateColumns="max-content minmax(0, 1fr)" gap={2}>
      <Image
        src={icon}
        sx={{
          width: iconWidth,
          height: iconWidth,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        imageProps={{
          style: {
            objectFit: 'contain',
            mixBlendMode: 'darken',
            ...iconSize[size]
          }
        }}
      />
      <Box display="flex" gap={1} flexDirection="column">
        <Typography
          color={nameColor}
          sx={{
            ...typography.base,
            ...typography.medium,
            // text overflow ellipsis
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {name}
        </Typography>
        <Typography color={descriptionColor} sx={{ ...typography.sm, ...typography.regular }}>
          {description}
        </Typography>
        {!!href && <ExternalLink href={href} mt={0.5} ariaLabel={`Visit the website for ${name}`} />}
      </Box>
    </Container>
  );
};

export default Partner;
