import React, { useState } from 'react';
import { Button, useTheme, Box, BoxProps } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

interface Props {
  children?: React.ReactNode;
  label: string;
}

const NavCollapse = ({ label, children, ...boxProps }: Props & BoxProps): JSX.Element => {
  const { typography } = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        variant='text'
        endIcon={<FeatherIcon icon={open ? 'chevron-up' : 'chevron-down'} size={16} />}
        size='medium'
        onClick={() => setOpen(pre => !pre)}
        sx={{ ...typography.sm }}
      >
        {label}
      </Button>
      {open &&
        <Box display="flex" flexDirection="column" gap={1} alignItems="baseline" pl={4} {...boxProps}>
          {children}
        </Box>
      }
    </>
  );
};

export default NavCollapse;
