import React, { createContext, useEffect, useState, useCallback } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type ChatOpenProps = {
  isFirstUserInteraction: boolean;
};
export let chatOpen: (props?: ChatOpenProps) => void;
export let sendMessage: (string) => void;
export let sendPayload: (string) => void;

type ChatbotState = {
  isChatOpen: boolean;
  isChatbotConnected: boolean;
  scriptLoaded: boolean;
};

interface ChatbotStoreProviderProps {
  children: React.ReactNode;
  initPayload?: string;
  subtitle?: string;
  embeded?: boolean;
  botType?: string;
  showSummary?: boolean;
}

const initialState = {
  isChatOpen: false,
  isChatbotConnected: false,
  scriptLoaded: false,
};

export const ChatbotContext = createContext<{ chatbotState: ChatbotState }>({
  chatbotState: initialState,
});

export const ChatbotStoreProvider = (props: ChatbotStoreProviderProps) => {
  const [isChatOpen, setChatOpen] = useState<boolean>(false);
  const [isChatbotConnected, setConnected] = useState<boolean>(false);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const onWidgetEvent = {
    onChatOpen: () => setChatOpen(true),
    onChatClose: () => setChatOpen(false),
  };

  const onSocketEvent = {
    connect: () => {
      console.log("connection established");
      setConnected(true);
    },
    disconnect: () => {
      console.log("connection ended");
    },
  };

  const chatOpenTrigger = (fn: () => void) => {
    chatOpen = fn;
  };

  const sendMessageTrigger = (fn: (string) => void) => {
    sendMessage = fn;
  };

  const sendPayloadTrigger = (fn: (string) => void) => {
    sendPayload = fn;
  };

  const appendChatScript = useCallback(
    (scriptToAppend: string) => {
      const script = document.createElement("script");
      script.src = scriptToAppend;
      script.async = true;

      script.onload = async () => {
        await window.WebChat.default(
          {
            id: process.env.REACT_APP_WEDDING_BOT || "65e18047b26fd2526e096cd0",
            initPayload: props.initPayload ?? "/get_started",
            customData: {
              language: "en",
            },
            params: {
              storage: "session",
            },
            title: "Indemn",
            subtitle: props.subtitle ?? "Wedding Events Chatbot",
            showSummary: props.showSummary ?? true,
            socketUrl: process.env.REACT_APP_SOCKET_MIDDLEWARE_URL,
            botType: props.botType ?? "wedding",
            conversationUrl: `${process.env.REACT_APP_CONVERSATION_URL}`,
            conversationAccessToken:
              process.env.REACT_APP_CONVERSATION_ACCESS_TOKEN,
            socketPath: "/socket.io/",
            isSyncEnabled: process.env.REACT_APP_TILEDESK_SYNC_ENABLED
              ? process.env.REACT_APP_TILEDESK_SYNC_ENABLED
              : false,
            opsUrl: process.env.REACT_APP_OPS_API_HOST,
            stripePK:
              "pk_live_51J9htNFp1ZWgizbCML4dwMCkl5zWLUp8SpCfsw8Xnyrnui0fugzWRwjq1ZzuTEL8RxWCXSpwdt5U6kx1xfLNVvGY00UDBU94Iy",
            profileAvatar: true,
            embedded: props.embeded,
            onWidgetEvent,
            onSocketEvent,
            chatOpenTrigger,
            sendMessageTrigger,
            sendPayloadTrigger,
          },
          null
        );
        setScriptLoaded(true);
      };

      document.body.appendChild(script);
    },
    [props]
  );

  const removeChatScript = (scriptToremove: string) => {
    const allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  };

  useEffect(() => {
    sessionStorage.clear(); // TODO: need a tactic to manage session.
    const src = process.env.REACT_APP_POS_SCRIPT_URL
      ? process.env.REACT_APP_POS_SCRIPT_URL
      : "https://s3.amazonaws.com/indemn.ai/pos-script/dev/index-dev.js";
    appendChatScript(src);
    return () => {
      removeChatScript(src);
    };
  }, [appendChatScript]);

  return (
    <ChatbotContext.Provider
      value={{
        chatbotState: {
          isChatOpen,
          isChatbotConnected,
          scriptLoaded,
        },
      }}
    >
      {props.children}
    </ChatbotContext.Provider>
  );
};
