import React from "react";
import { GridContainer, Container, Image } from "components";
import { useTheme, Typography } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { Feature, AskIndmenButtonLatest } from "views/components";
import JumboCard from "blocks/JumboCard";
import { Box } from "@mui/material";
import * as constants from "utils/constants";

const features = [
  {
    title: "Dedicated landing pages",
    content:
      "Each page is equipped with a conversational UI that will take your clients’ breath away",
  },
  {
    title: "Rich, informative customer experience",
    content:
      "Answer questions on the spot, enabling a confident, friction-less purchase",
  },
  {
    title: "Real-time monitoring and compliance",
    content: "Secure the best-in-market compliant policy for your needs",
  },
];

const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <>
      {/* hero background */}
      <Box
        sx={{
          position: "absolute",
          width: {
            xs: "33.6vh",
            sm: "37.8vh",
            md: "42vh",
            lg: "46.2vh",
            xl: "50.4vh",
          }, // 70% of original size
          height: {
            xs: "33.6vh",
            sm: "37.8vh",
            md: "42vh",
            lg: "46.2vh",
            xl: "50.4vh",
          }, // 70% of original size
          right: 260,
          top: { xs: "6vh", md: "14vh", xl: "9vh" },
          overflow: "hidden", // Ensures the image stays inside the rounded corners
          borderRadius: "18px", // Adjust this value for rounding
        }}
      >
        <Image
          src={constants.CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE}
          alt="Partner with us"
          sx={{
            width: "100%",
            height: "100%",
          }}
          imageProps={{
            style: {
              objectFit: "cover",
              mixBlendMode: "darken",
            },
          }}
        />
      </Box>

      <GridContainer
        sx={{
          display: "flex",
          flexDirection: { xs: "column", xl: "row" },
          pt: { xs: "30vh", md: "25vh", xl: "20vh" },
        }}
      >
        <Container
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}
        >
          <Typography
            color="primary.900"
            sx={{ ...typography["4xl"], ...typography.bold }}
          >
            Join our growing network
          </Typography>
          <Typography
            variant="h5"
            color="primary.900"
            sx={{ ...typography.base, ...typography.regular }}
          >
            We’ve partnered with hundreds of businesses across the U.S.,
            delivering conversational insurance to their network.
          </Typography>
          <AskIndmenButtonLatest
            variant="contained"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
            sx={{ width: "fit-content" }}
            message="I'm looking to partner with EventGuard"
            // onClick={() =>
            //   window.open(
            //     "https://airtable.com/appZTeA2fGFOS0tNv/shr8QixOFnrItxbVc",
            //     "_blank"
            //   )
            // }
          >
            Partner with EventGuard
          </AskIndmenButtonLatest>
        </Container>
      </GridContainer>
      {/* features */}

      {/* <FeaturesGrid></FeaturesGrid> */}
      <GridContainer
        display="grid"
        gridTemplateColumns={{ xs: "1f", xl: "1fr 1fr 1fr" }}
        gap={{ xs: 4, xl: 0.5 }}
      >
        {features.map((feature) => (
          <Feature key={feature.title} {...feature} />
        ))}
      </GridContainer>
      <JumboCard />
    </>
  );
};

export default Hero;
