import React, { useEffect } from "react";
import Main from "layouts/main";
import Hero from "./hero";
import Team from "./team";
import History from "./history";
import Investors from "./investors";
import Features from "./features";
import Values from "./values";

const About = (): JSX.Element => {
  useEffect(() => {
    window.onload = () => {
      if (window.location.hash) {
        window.location.href = window.location.hash;
      }
    };
  }, []);

  return (
    <Main>
      <Hero />
      <Features />
      <Team />
      <Investors />
      <Values />
      <History />
    </Main>
  );
};

export default About;
