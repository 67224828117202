import React from 'react';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import POS from './pos';

export default function Pos(): JSX.Element {
  if (process.env.REACT_APP_ENV !== 'development') {
    window.location.href = '/';
  }

  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : 'insurancetrak'}
      showSummary={false} // TODO: For only demo on March 6
    >
      <POS />
    </ChatbotStoreProvider >
  );
}