import React from 'react';
import { Box, Link, Typography, IconButton, useTheme } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { GridContainer, Logo, Divider, Container } from 'components';
import AskIndmenButton from 'views/components/ask-indemn-button';

const socialIcons = [
  { icon: 'twitter', href: 'https://twitter.com/IndemnAi', ariaLabel: 'Link to Indemn\'s X or Twitter page' },
  { icon: 'instagram', href: 'https://instagram.com/indemn.ai', ariaLabel: 'Link to Indemn\'s Instagram page' },
  { icon: 'facebook', href: 'https://www.facebook.com/indemn.insurance', ariaLabel: 'Link to Indemn\'s Facebook page' },
  { icon: 'linkedin', href: 'https://www.linkedin.com/company/indemn', ariaLabel: 'Link to Indemn\'s LinkedIn page' },
];

const links = {
  'Get insurance': [
    { text: 'Wedding insurance', href: '/get-insurance/wedding' },
    { text: 'Event insurance', href: '/get-insurance/event' },
    { text: 'Partner with us', href: '/partner-with-us' }
  ],
  'Company': [
    { text: 'About', href: '/about' },
    { text: 'Investors', href: '/about#investors' },
    { text: 'Team', href: '/about#team' },
    { text: 'Contact us', href: 'mailto:info@indemn.ai', icon: 'mail' }
  ],
  'Sample polices': [
    { text: 'Event liability insurance', icon: 'file-text', target: '_blank', href: 'https://drive.google.com/file/d/1l476LIA4mgNXZYw91JZ4Jev-3Y8QEHKm/view' },
    { text: 'Event cancellation insurance', icon: 'file-text', target: '_blank', href: 'https://drive.google.com/file/d/1SXFerVYZO92mVWJ9YVj_JK1kacxqWbYu/view' }
  ],
};

const FooterLinks = ({ title, links = [] }: { title: string; links: { text: string; href: string, icon?: string, target?: string }[] }): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box>
      <Typography color="primary.300" sx={{ ...typography.sm, ...typography.medium }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1.5,
          ml: 0.75,
          gap: 0.75
        }}
      >
        {links.map((e) => (
          <Link
            key={e.text}
            href={e.href}
            target={e.target}
            underline="none"
            component="a"
            color='white'
            sx={{
              ...typography.sm, ...typography.regular,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover': {
                color: palette.tertiary[200]
              }
            }}
          >
            {e.text}
            {e.icon && <FeatherIcon icon={e.icon} size={16} />}
          </Link>
        ))}
      </Box>
    </Box>
  );
};

const Footer = ({ isExtended = false }: { isExtended?: boolean; }): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box sx={{ background: palette.background.footer }}>
      <GridContainer pt={4} pb={{ xs: 30, md: 6 }}>
        <Container
          sx={{
            display: isExtended ? 'flex' : 'none',
            flexDirection: 'column',
            gap: 3,
            width: { xs: 1, md: 6 / 8, xl: 7 / 12 },
            margin: { xs: 0, md: '0 auto', xl: '0 8.3%' }
          }}
        >
          <Typography
            variant='h3'
            color="primary.50"
            sx={{
              ...typography['4xl'],
              ...typography.bold,
              fontSize: { xs: typography['2xl'].fontSize, md: typography['4xl'].fontSize, xl: typography['3xl'].fontSize }
            }}>
            Start your insurance conversation today
          </Typography>
          <Typography
            color="white"
            sx={{
              ...typography.base,
              ...typography.regular,
              fontSize: { xs: typography.base.fontSize, xl: typography.lg.fontSize }
            }}
          >
            Supercharge your sales channels using the power of GPT-driven conversations.
          </Typography>
          <AskIndmenButton
            variant='contained'
            endIcon={<FeatherIcon icon="message-circle" />}
            size='large'
            color='secondary'
            sx={{ width: 'fit-content' }}
            message="I'm looking to power my business through Indemn"
          >
            Become Powered by Indemn
          </AskIndmenButton>
        </Container>
        <Divider my={{ xs: 6, md: 4 }} display={isExtended ? 'block' : 'none'} />
        <Box
          sx={{
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: { xs: 'unset', md: '3fr 5fr', xl: '3fr 9fr' },
            flexDirection: { xs: 'column-reverse', md: 'unset' },
          }}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: { xs: 6, md: 0 }
            }}
          >
            <Logo isDark />
            <Typography color="monochrome.zinc.200" sx={{ ...typography.xs, ...typography.regular }}>
              5 Union Square West, #1383
              <br />
              New York, NY 10003
              <br />
              <br />
              NY License #BR-1665925
            </Typography>
            <Box display="flex" alignItems="center" gap={1.5}>
              {socialIcons.map(({ icon, href, ariaLabel }) => (
                <IconButton href={href} key={icon} sx={{ p: 0 }} target='_blank' aria-label={ariaLabel}>
                  <FeatherIcon icon={icon} color="white" />
                </IconButton>
              ))}
            </Box>
            <Typography color="monochrome.zinc.200" sx={{ ...typography.xs, ...typography.regular }}>
              © {new Date().getFullYear()} Indemn Inc. All rights reserved.
            </Typography>
          </Container>
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr' },
              gap: { xs: 2.5, md: 4.5, xl: 6.5 }
            }}
          >
            {Object.keys(links).map(key => (
              <FooterLinks key={key} title={key} links={links[key]} />
            ))}
          </Container>
        </Box>
      </GridContainer >
    </Box >
  );
};

export default Footer;
