import React from 'react';
import GridContainer from 'components/grid-container';
import Feature from 'views/components/feature';

const features = [
  { icon: '/images/icons/feature-ai.svg', title: 'GPT Driven Virtual Assistants', content: 'Engage with a platform able to quote, underwrite and bind coverage through natural conversation' },
  { icon: '/images/icons/feature-magic.svg', title: 'Copilot & Continuous Improvement', content: 'Unlock Large Language Model access for your agents and service staff, and build your own custom models' },
  { icon: '/images/icons/feature-compliant.svg', title: 'Next Generation Distribution', content: 'Deliver an AI powered conversational experience across your most important distribution channels' }
];

const Features = (): JSX.Element => {
  return (
    <GridContainer
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
        gap: { xs: 1.5, xl: 0.5 },
      }}
    >
      {features.map(feature => (
        <Feature key={feature.title} {...feature} />
      ))}
    </GridContainer>
  );
};

export default Features;