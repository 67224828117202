import React from 'react';
import { CloudImage, Container, GridContainer } from 'components';
import { useTheme, Typography, Box } from '@mui/material';

const partnerLogos = ['partner-logo-01', 'partner-logo-02'];

const Partners = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display='flex' flexDirection='column' width={{ xs: 4 / 4, md: 4 / 8, xl: 4 / 12 }} gap={{ xs: 4, md: 2 }}>
      <Container>
        <Typography color='primary.900' sx={{ ...typography.lg, ...typography.regular }} textAlign='center'>
          Our early partners
        </Typography>
      </Container>
      <Box display='grid' gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} gap={{ xs: 1.5, md: 4, xl: 6 }} width={{ xs: 1 / 2, md: 1 }} margin='auto'>
        {partnerLogos.map(id => (
          <Container
            key={id}
            sx={{
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.32)',
              background: 'rgba(255, 255, 255, 0.12)'
            }}
          >
            <CloudImage id={id} />
          </Container>
        ))}
      </Box>
    </GridContainer>
  );
};

export default Partners;