import React from "react";
import './VenuesGrid.css'; // Updated CSS import

interface Testimonial {
  recommendation: string;
  image: string;
  name: string;
  role: string;
  href: string;
}

interface VenuesGridProps {
  testimonials: Testimonial[];
}

const VenuesGrid: React.FC<VenuesGridProps> = ({ testimonials }) => {
  return (
    <div className="venues-container">
      <div className="venues-grid">
        {testimonials.map((testimonial, index) => (
          <a key={index} href={testimonial.href} target="_blank" rel="noopener noreferrer" className="venue-card">
            <img src={testimonial.image} alt={`Logo of ${testimonial.name}`} className="venue-logo" />
            <div className="venue-quote">
              <p>{testimonial.recommendation}</p>
              <p className="venue-name">{testimonial.name}</p>
              <p className="venue-title">{testimonial.role}</p>
            </div>
          </a>
        ))}
      </div>

      {/* Read More Testimonials Button */}
      <div className="read-more-container">
        {/* <a href="/testimonials" className="read-more-button">
          Read more testimonials
          <svg width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.647-3.646a.5.5 0 1 1 .708-.708l4.5 4.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>
        </a> */}
      </div>
    </div>
  );
};

export default VenuesGrid;
