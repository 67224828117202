import React from "react";
import { Box, BoxProps } from "@mui/material";

interface Props {
  children?: React.ReactNode;
}

const Container = ({
  children,
  ...boxProps
}: Props & BoxProps): JSX.Element => (
  <Box
    px={{ xs: 1, md: 2, xl: 3 }}
    py={{ xs: 1.5, md: 2, xl: 2.5 }}
    {...boxProps} // Spread props to allow attributes like `data-*` to pass through
    sx={{
      ...boxProps.sx, // Merge custom styles
    }}
  >
    {children}
  </Box>
);

export default Container;
