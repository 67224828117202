import React, { useState, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

const testimonials = [
    {
        name: 'Carley E',
        description: 'We could not be happier with EventGuard',
        venue_name: 'Venue Owner'
    },
    {
        name: 'Anne R',
        description: 'Working with EventGuard has been a value added for our wedding venue',
        venue_name: 'Venue Owner'
    },
    {
        name: 'Eliza K',
        description: 'EventGuard has been awesome to work with!',
        venue_name: 'Venue Manager'
    },
    {
        name: 'Jenn',
        description: 'EventGuard makes the process simple and straightforward',
        venue_name: 'Venue Owner'
    },
    {
        name: 'Margaret M',
        description: 'EventGuard has been a wonderful resource',
        venue_name: 'Venue Owner'
    },
    {
        name: 'Rob S',
        description: 'Its so simple and easy to use and the cost of insurance is super affordable',
        venue_name: 'Venue Owner'
    }
];

const SocialProofCarousel = () => {
    const { typography } = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Update layout on window resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const nextTestimonial = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const goToTestimonial = (index: number) => {
        setIsTransitioning(true);
        setCurrentIndex(index);
    };

    useEffect(() => {
        if (isTransitioning) {
            const timer = setTimeout(() => setIsTransitioning(false), 300);
            return () => clearTimeout(timer);
        }
    }, [isTransitioning]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            padding: isMobile ? '0 16px' : '0 32px',
            margin: isMobile ? '64px 0 84px' : '128px 0 168px'
        }}>
            <IconButton onClick={prevTestimonial} style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: isMobile ? '-10px' : '-20px',
                width: isMobile ? 32 : 40,
                height: isMobile ? 32 : 40,
                color: '#3b82f6',
                fontSize: isMobile ? '20px' : '24px'
            }}>
                <ChevronLeftIcon />
            </IconButton>
            <div style={{ overflow: 'hidden' }}>
                <div
                    style={{
                        display: 'flex',
                        transition: 'transform 0.3s ease-in-out',
                        transform: `translateX(-${currentIndex * (isMobile ? 100 : 50)}%)`,
                        opacity: isTransitioning ? 0.5 : 1
                    }}
                >
                    {testimonials.map((testimonial) => (
                        <div key={testimonial.name} style={{
                            flexShrink: 0,
                            width: isMobile ? '100%' : '50%',
                            padding: isMobile ? '0 16px' : '0 32px'
                        }}>
                            <div style={{
                                backgroundColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), rgba(255, 255, 255, 0.40)',
                                padding: isMobile ? '16px 24px' : '20px 40px',
                                borderRadius: '12px',
                                boxShadow: '0.2px 0.2px 0.3px rgba(145, 145, 145, 0.34), 0.6px 0.6px 1px rgba(145, 145, 145, 0.38)',
                                border: '1px solid #FFFFFF52',
                                backdropFilter: 'blur(32px)',
                                height: isMobile ? 'auto' : 240,
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: '16px'
                            }}>
                                <Box display="flex" alignItems="center">
                                    {[...Array(5)].map((_, i) => (
                                        <span key={i} style={{
                                            color: '#3b82f6',
                                            fontSize: isMobile ? '16px' : '20px'
                                        }}>★</span>
                                    ))}
                                </Box>
                                <Typography color="primary.800" style={{
                                    fontSize: isMobile ? '14px' : typography.lg.fontSize,
                                    flexGrow: 1
                                }}>
                                    {testimonial.description}
                                </Typography>
                                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                                    <Avatar style={{
                                        width: isMobile ? 24 : 32,
                                        height: isMobile ? 24 : 32
                                    }}>
                                        <ThumbUpOffAltIcon style={{ fontSize: isMobile ? '16px' : '20px' }} />
                                    </Avatar>
                                    <Stack>
                                        <Typography color="primary.800" style={{
                                            fontWeight: 'bold',
                                            fontSize: isMobile ? '14px' : typography.bold.fontSize
                                        }}>
                                            {testimonial.name}
                                        </Typography>
                                        <Typography color="primary.800" style={{
                                            fontSize: isMobile ? '12px' : typography.regular.fontSize
                                        }}>
                                            {testimonial.venue_name}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <IconButton onClick={nextTestimonial} style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: isMobile ? '-10px' : '-20px',
                width: isMobile ? 32 : 40,
                height: isMobile ? 32 : 40,
                color: '#3b82f6',
                fontSize: isMobile ? '20px' : '24px'
            }}>
                <ChevronRightIcon />
            </IconButton>
            <div style={{
                position: 'absolute',
                bottom: '-40px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '8px'
            }}>
                {testimonials.map((_, index) => (
                    <div
                        key={index}
                        onClick={() => goToTestimonial(index)}
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: index === currentIndex ? '#3b82f6' : '#d1d5db',
                            cursor: 'pointer'
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default SocialProofCarousel;
