import React, { createContext, useEffect, useState } from "react";

type PageState = {
  events: Event[];
};

interface PageStoreProviderProps {
  children: React.ReactNode;
}

const initialState = {
  events: [],
};

export const PageContext = createContext<{ pageState: PageState }>({
  pageState: initialState,
});

export const PageStoreProvider = (props: PageStoreProviderProps) => {
  const [events, setEvent] = useState<Event[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await (
          await fetch(`${process.env.REACT_APP_OPS_API_HOST}/fetch-products`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
        ).json();

        setEvent(res.products);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, []);

  return (
    <PageContext.Provider
      value={{
        pageState: {
          events,
        },
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};
