import React, { useEffect, useState } from "react";
import Main from "layouts/main";
import { ChatbotStoreProvider } from "context/chatbot-state";
import Display from "./display";
import { useTheme, Box } from "@mui/material";
import TwoColumnCard from "../../blocks/TwoColumnCard";
import { BotHostContainer } from "views/components";
import { GridContainer, Image } from "components";
import { Loading } from "components";
import NotFound from "views/not-found";
import { parseAdditionalSlots } from "utils/helper-generic";
import JumboCardAlt from "blocks/JumboCardAlt";

const Weddings = (): JSX.Element => {
  const { palette } = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    const fetchProductBySlug = async () => {
      setLoading(true);
      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/fetch-product-by-slug`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ slug: "wedding" }),
            }
          )
        ).json();

        setEvent(res.product);

        // update page title
        document.title = res.product.page_title;
        // update page description
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            res.product.page_meta_description
          );
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    fetchProductBySlug();
  }, []);

  if (loading) {
    return <Loading loading={!event} />;
  }

  if (!event) {
    return <NotFound />;
  }

  const payload = `/get_started${JSON.stringify({
    first_message: event.first_message,
    package_config: event.package_config,
    mandate_liability: event.mandate_liability,
    mandate_cancellation: event.mandate_cancellation,
    path_type: "product",
    product: event.slug,
    cancellation_available: true,
    ...parseAdditionalSlots(event.additional_slots),
  })}`;

  return (
    <ChatbotStoreProvider
      botType={
        process.env.REACT_APP_BOT_TYPE
          ? process.env.REACT_APP_BOT_TYPE
          : "wedding"
      }
      initPayload={payload}
      showSummary={true}
    >
      <Main>
        {/* hero background */}
        <Image
          src="https://res.cloudinary.com/deksgbacz/image/upload/v1698113613/wedding_hero_vibrant_v6_awyqnx.png"
          sx={{
            display: "flex",
            background: palette.background.directInsuranceHero,
            clipPath: "url(#myClip)",
            height: { xs: "70vh", md: "50vh", xl: "unset" },
          }}
          imageProps={{
            style: {
              objectFit: "cover",
              mixBlendMode: "overlay",
            },
          }}
        />

        <svg width="0" height="0">
          <defs>
            <clipPath id="myClip" clipPathUnits="objectBoundingBox">
              <path
                transform="scale(0.00069444444, 0.00202839756)"
                d="M1440 0H0V438.385C493.657 513.754 891.5 507 1440 438.385V0Z"
              ></path>
            </clipPath>
          </defs>
        </svg>

        <Box
          sx={{
            background:
              "radial-gradient(68.79% 89.54% at 114.1% -18.98%, rgba(0, 99, 247, 0.20) 0%, rgba(85, 133, 255, 0.20) 50.52%, rgba(245, 249, 255, 0.20) 100%), linear-gradient(326deg, rgba(255, 255, 255, 0.88) 48.8%, rgba(220, 219, 254, 0.88) 99.98%)",
            mt: { xs: "-30vh", md: "-12vh" },
          }}
        >
          <GridContainer>
            <TwoColumnCard title="title" text="text" />
            <Display />
            <JumboCardAlt title="title" text="text" />
            <BotHostContainer />
          </GridContainer>
        </Box>
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Weddings;
