import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useFAQ } from 'hooks/use-faq';
import NotFound from 'views/not-found';
import { BotHostContainer, Faq } from 'views/components';
import Loading from 'components/loading';
import Hero from './hero';
import { parseAdditionalSlots } from 'utils/helper-generic';

const Venue = (): JSX.Element => {
  const { slug } = useParams();
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { faqs } = useFAQ(venue?.faq_table, venue?.faq_list);

  useEffect(() => {
    const fetchPartnerBySlug = async () => {
      setLoading(true);

      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/fetch-venue-by-slug`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ slug }),
            },
          )
        ).json();

        setVenue(res.venue);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    if (slug) {
      fetchPartnerBySlug();
    }
  }, [slug]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (!venue) {
    return <NotFound />;
  }

  const payload = `/get_started${JSON.stringify({
    form_share_venue_address: venue.venue_address,
    form_venue_name: venue.venue_name,
    venue_name: venue.venue_name,
    venue_address: venue.venue_address,
    partner_lead: true,  // phase this out after checking in Botfront
    partner_type: 'venue', // phase this out after checking in Botfront
    path_type: 'venue',
    package_config: venue.package_config,
    state: venue.state_code,
    partner_id: venue.slug,
    cancellation_available: venue.cancellation_available,
    first_message: venue.first_message,
    form_venue_email: venue.form_venue_email,
    send_certificate_to_venue: true,
    mandate_liability: venue.mandate_liability,
    mandate_cancellation: venue.mandate_cancellation,
    ...parseAdditionalSlots(venue.additional_slots)
  })}`;

  return (
    <ChatbotStoreProvider
      botType={process.env.REACT_APP_BOT_TYPE ? process.env.REACT_APP_BOT_TYPE : venue.bot}
      initPayload={payload}
    >
      <Main>
        <Hero venue={venue} />
        <BotHostContainer />
        <Faq faqs={faqs} />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Venue;
