export function isMobile(): boolean {
  if (/(iphone|android|blackberry|webos)/i.test(navigator.userAgent)) {
    return true;
  }

  return false;
}

export function parseAdditionalSlots(str: string): { [key: string]: any } {
  if (!str) return {};

  try {
    const parsed = JSON.parse(str);
    if (
      typeof parsed === "object" &&
      !Array.isArray(parsed) &&
      parsed !== null
    ) {
      return parsed;
    }
  } catch (err) {
    console.log("Failed to parse additional_slots: ", err);
    return {};
  }
}

export function parseNavItem(str: string): {
  icon: string;
  tag: string;
  text: string;
} {
  const matches = str.match(/\{.+\}/);
  const info = matches ? matches[0] : "";
  const text = str.replaceAll(/\{.+\}/g, "").trim();

  if (info) {
    try {
      const parsed = JSON.parse(info);
      if (
        typeof parsed === "object" &&
        !Array.isArray(parsed) &&
        parsed !== null
      ) {
        return { icon: "", tag: "", text, ...parsed };
      }
    } catch (err) {
      console.log("Failed to nav item: ", err);
    }
  }

  return { icon: "", tag: "", text };
}

export function scrollToTop(idStr: string) {
  const element = document.getElementById(idStr);
  if (!element) return;

  const topPosition = element.getBoundingClientRect().top + window.scrollY;

  window.scrollTo({
    top: topPosition - 128, // Specify the vertical position you want to scroll to
    behavior: "smooth", // Use 'smooth' for smooth scroll behavior, or 'auto' for instant scroll
  });
}

export function createBotPayload(
  data: {
    [key: string]: any;
  },
  bot: string,
  firstMessage: string
) {
  let payload: any = data;

  let botId = bot;
  let botType = bot;
  switch (bot) {
    case "wedding":
      return {
        payload: `/get_started${JSON.stringify(payload)}`,
        botId: process.env.REACT_APP_WEDDING_BOT || "65e18047b26fd2526e096cd0",
        botType: bot,
      };
    case "event":
      botId = process.env.REACT_APP_EVENT_BOT;
      botType = process.env.REACT_APP_EVENT_BOT;
      break;
    case "weddingv2":
      payload["isWeddingV2"] = true;
      botId = process.env.REACT_APP_WEDDING_V2_BOT;
      botType = process.env.REACT_APP_WEDDING_V2_BOT;
      break;
  }

  if (firstMessage) {
    payload.first_message = {
      text: firstMessage,
      quick_replies: [
        {
          title: "Get a Quote",
          payload: "Get a Quote",
        },
      ],
    };
  }
  return {
    payload,
    botId: botId,
    botType: botType,
  };
}
