import React from "react";
import { Box } from "@mui/material";
import { GridContainer, Image } from "components";
import * as constants from "utils/constants";

const investors = [
  {
    src: constants.CLOUDINARY_IMAGES.INVESTOR_AFTERWORK_LOGO,
    alt: "Afterwork Ventures",
  },
  { src: constants.CLOUDINARY_IMAGES.INVESTOR_THE_FUND_LOGO, alt: "The Fund" },
  { src: constants.CLOUDINARY_IMAGES.INVESTOR_MARKD_LOGO, alt: "Markd" },
];

const Investors = () => (
  <GridContainer id="investors">
    <Box
      display="grid"
      gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr 1fr" }}
      gap={{ xs: 0.5, md: 4.5, xl: 6.5 }}
    >
      {investors.map((investor, index) => (
        <Image
          key={index}
          src={investor.src}
          alt={investor.alt}
          width={{ xs: 180, md: 1 }}
          height={{ xs: 96, md: 160 }}
          justifySelf="center"
          imageProps={{
            style: {
              objectFit: "contain",
              mixBlendMode: "darken",
            },
          }}
        />
      ))}
    </Box>
  </GridContainer>
);

export default Investors;
