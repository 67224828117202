import React, { createContext, useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type ChatOpenProps = {
  isFirstUserInteraction: boolean;
};
export let chatOpen: (props?: ChatOpenProps) => void;
export let sendMessage: (string) => void;
export let sendPayload: (string) => void;

type ChatbotState = {
  isChatOpen: boolean;
  isChatbotConnected: boolean;
  scriptLoaded: boolean;
};

interface ChatbotStoreProviderProps {
  children: React.ReactNode;
  initPayload?: string;
  subtitle?: string;
  embeded?: boolean;
  botType?: string;
  showSummary?: boolean;
}

const initialState = {
  isChatOpen: false,
  isChatbotConnected: false,
  scriptLoaded: false,
};

export const ChatbotContext = createContext<{ chatbotState: ChatbotState }>({
  chatbotState: initialState,
});

export const ChatbotStoreProvider = (props: ChatbotStoreProviderProps) => {
  const [isChatOpen, setChatOpen] = useState<boolean>(false);
  const [isChatbotConnected, setConnected] = useState<boolean>(false);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const onWidgetEvent = {
    onChatOpen: () => setChatOpen(true),
    onChatClose: () => setChatOpen(false),
  };

  const onSocketEvent = {
    connect: () => {
      console.log("connection established");
      setConnected(true);
    },
    disconnect: () => {
      console.log("connection ended");
    },
  };

  const chatOpenTrigger = (fn: () => void) => {
    chatOpen = fn;
  };

  const sendMessageTrigger = (fn: (string) => void) => {
    sendMessage = fn;
  };

  const sendPayloadTrigger = (fn: (string) => void) => {
    sendPayload = fn;
  };

  const appendChatScript = (scriptToAppend: string) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;

    script.onload = async () => {
      await window.WebChat.default(
        {
          id: process.env.REACT_APP_WEDDING_BOT || "65e18047b26fd2526e096cd0",
          initPayload: props.initPayload ?? "/get_started",
          // arbitrary custom data. Stay minimal as this will be added to the socket
          customData: {
            language: "en",
          },
          params: {
            storage: "session", // can be set to 'local'  or 'session'. details in storage section.
          },
          title: "Indemn",
          subtitle: props.subtitle ?? "Wedding Events Chatbot",
          showSummary: props.showSummary ?? true,
          socketUrl: process.env.REACT_APP_SOCKET_MIDDLEWARE_URL,
          botType: props.botType ?? "wedding",
          socketPath: "/socket.io/",
          opsUrl: process.env.REACT_APP_OPS_API_HOST,
          embedded: props.embeded,
          onWidgetEvent,
          onSocketEvent,
          chatOpenTrigger,
          sendMessageTrigger,
          sendPayloadTrigger,
          launcherConfig: {
            useLauncher: false,
            launcherSize: 48,
            launcherType: null,
            launcherLabel: null,
            botPosition: null,
            launcherIcon: null,
          },
        },
        null
      );
    };

    document.body.appendChild(script);
    setScriptLoaded(true);
  };

  const removeChatScript = (scriptToremove: string) => {
    const allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  };

  useEffect(() => {
    sessionStorage.clear(); // TODO: need a tactic to manage session.
    const src = process.env.REACT_APP_POS_SCRIPT_URL
      ? process.env.REACT_APP_POS_SCRIPT_URL
      : "https://s3.amazonaws.com/indemn.ai/pos-script/dev/index-dev.js";
    appendChatScript(src);
    return () => {
      removeChatScript(src);
    };
  }, []);

  return (
    <ChatbotContext.Provider
      value={{
        chatbotState: {
          isChatOpen,
          isChatbotConnected,
          scriptLoaded,
        },
      }}
    >
      {props.children}
    </ChatbotContext.Provider>
  );
};
