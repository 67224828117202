import React, { useContext } from "react";
import { Button, ButtonProps } from "@mui/material";
import {
  ChatbotContext,
  chatOpen,
  sendMessage,
  sendPayload,
} from "context/chatbot-state";
import { scrollToTop } from "utils/helper-generic";

interface Props {
  payload?: string;
  message?: string;
  children?: React.ReactNode;
}

const AskIndmenButton = ({
  children,
  payload,
  message,
  ...buttonProps
}: Props & ButtonProps): JSX.Element => {
  const chatbotCtx = useContext(ChatbotContext);
  const { isChatOpen, scriptLoaded } = chatbotCtx.chatbotState;

  const handleAsk = () => {
    if (!scriptLoaded) {
      return;
    }

    if (payload && sendPayload) {
      payload = payload.startsWith("/") ? payload : `/${payload}`;
      sendPayload(payload);
    }

    if (message && sendMessage) {
      sendMessage(message);
    }

    if (!message && !isChatOpen && chatOpen) {
      chatOpen({ isFirstUserInteraction: true });
    }

    scrollToTop("indemn-webchat");
  };

  return (
    <Button onClick={handleAsk} {...buttonProps}>
      {children}
    </Button>
  );
};

export default AskIndmenButton;
