import React, { useContext, useEffect } from "react";
import { Container, GridContainer } from "components";
import { ChatbotContextLatest } from "context/chatbot-state-latest";
import { BoxProps } from "@mui/material";

interface BotHostContainerLatestProps extends BoxProps {
  initialPayload?: Object; // Add a prop for initialPayload
}

const BotHostContainerLatest: React.FC<BotHostContainerLatestProps> = ({
  initialPayload = {},
  ...boxProps
}) => {
  const { chatbotState } = useContext(ChatbotContextLatest);

  useEffect(() => {
    if (chatbotState.isAskIndemnActive) {
      console.log("The askIndemnButton was clicked!");
    }
  }, [chatbotState.isAskIndemnActive, initialPayload]);

  return (
    <GridContainer
      pb={chatbotState.isAskIndemnActive ? { xs: 12, md: 16 } : 0}
      {...boxProps}
    >
      <Container
        id="indemn-webchat"
        data-params={JSON.stringify(initialPayload)}
        height={chatbotState.isAskIndemnActive ? "80vh" : 0}
        px={0}
        py={chatbotState.isAskIndemnActive ? { xs: 1.5, md: 2, xl: 2.5 } : 0}
      />
    </GridContainer>
  );
};

export default BotHostContainerLatest;
