import React, { useEffect, useContext } from "react";
import { ChatbotStoreProvider } from "context/chatbot-state";
import { ChatbotContext, chatOpen } from "context/chatbot-state";
import Main from "layouts/main";
import { Image, GridContainer, Container } from "components";

const SurveyBot = (): JSX.Element => {
  const chatbotCtx = useContext(ChatbotContext);
  const { isChatbotConnected, isChatOpen } = chatbotCtx.chatbotState;

  useEffect(() => {
    if (isChatbotConnected && chatOpen && !isChatOpen) {
      chatOpen();
    }
  }, [isChatbotConnected, isChatOpen]);

  return (
    <Container
      id="indemn-webchat"
      sx={{
        height: 1,
        width: { xs: 1, xl: 8 / 12 },
      }}
    />
  );
};

const Survey = (): JSX.Element => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utm = urlParams.get("utm");
  console.log({ utm });

  return (
    <ChatbotStoreProvider
      botType="survey"
      initPayload="/faqs__list"
      showSummary={false}
    >
      <Main>
        {/* hero background */}
        <Image
          src="/images/heroes/404 hero.png"
          sx={{
            position: "absolute",
            height: "100%",
            width: 1,
            top: 0,
          }}
          imageProps={{
            style: {
              opacity: 0.24,
              objectFit: "contain",
              mixBlendMode: "darken",
            },
          }}
        />
        <GridContainer
          display="flex"
          justifyContent="center"
          pt={20}
          height={{ xl: "100vh" }}
        >
          <SurveyBot />
        </GridContainer>
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Survey;
