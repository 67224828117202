import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from 'components/page';
import './index.css';
import 'aos/dist/aos.css';
import { PageStoreProvider } from 'context/page-state';

const App = (): JSX.Element => {
  return (
    <Page>
      <BrowserRouter>
        <PageStoreProvider>
          <Routes />
        </PageStoreProvider>
      </BrowserRouter>
    </Page>
  );
};

export default App;
