export const CLOUDINARY_IMAGES = {
  HUE_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729071378/indemn-website/heros/hue_gou5pp.png", // src/layouts/main & src/views/partners
  PAGE_NOT_FOUND_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729073428/indemn-website/heros/404_hero_kq6ffb.png", // src/views/not-found & src/views/surveys
  HOME_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729073429/indemn-website/heros/home_hero_gmbbbe.png", // src/views/home
  ABOUT_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729073428/indemn-website/heros/about_hero_w40q7w.png", // src/views/about
  PARTNER_US_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729073429/indemn-website/heros/partner_us_hero_lihnho.png", // src/views/partners
  PRODUCT_HERO_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729073430/indemn-website/heros/product_hero_alkquy.png", // src/views/products
  INVESTOR_AFTERWORK_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075287/indemn-website/investors/afterwork_logo_jsmfka.png",
  INVESTOR_MARKD_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075287/indemn-website/investors/markd_logo_l57zq0.png",
  INVESTOR_THE_FUND_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075288/indemn-website/investors/the_fund_logo_mkj0bn.png",
  PARTNER_THE_SCHOOL_HOUSE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075778/indemn-website/partners/The_School_House_Venue_pslzw0.png",
  PARTNER_THE_SCHOOL_HOUSE_LOGO_DARK:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731918020/indemn-website/partners/The_School_House_cvkj0t.png",
  PARTNER_THE_RUINES_AT_SASSAFRAS_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075778/indemn-website/partners/The_Ruins_at_Sassafras_t2oeza.png",
  PARTNER_THE_PASEO_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075777/indemn-website/partners/The_Paseo_xmcs2o.png",
  PARTNER_STONEGATE_MANOR_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075776/indemn-website/partners/Stonegate_Manor_p0wfsu.png",
  PARTNER_SHADY_BROOK_ACRES_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075776/indemn-website/partners/Shady_Brook_Acres_Hitching_Post_ubkd9s.png",
  PARTNER_SEA_PEARL_BEACH_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075775/indemn-website/partners/Sea_Pearl_Beach_House_Weddings_l3k5r4.png",
  PARTNER_POSEY_MEADOWS_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075774/indemn-website/partners/Posey_Meadows_idw8kx.png",
  PARTNER_PEACOCK_RIDGE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075774/indemn-website/partners/Peacock_Ridge_lb3p94.jpg",
  PARTNER_OLD_WIDE_AWAKE_PLANTATION_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075773/indemn-website/partners/Old_Wide_Awake_Plantation_ohsdxq.jpg",
  PARTNER_OLD_WIDE_AWAKE_PLANTATION_DARK_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731917764/indemn-website/partners/Old_Wide_Awake_Plantation_mlslny.png",
  PARTNER_MADERA_ESTATES_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075772/indemn-website/partners/Madera_Estates_mkxfbt.png",
  PARTNER_KNOXVILLE_BOTANICAL_GARDEN_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075771/indemn-website/partners/Knoxville_Botanical_Garden_and_Arboretum_qa4afb.png",
  PARTNER_GAINY_VINEYARD_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075771/indemn-website/partners/Gainey_Vinyard_fe4xss.jpg",
  PARTNER_HAWKESDENE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075771/indemn-website/partners/Hawkesdene_jqg6tx.png",
  PARTNER_EVER_AFTER_FARMS_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075771/indemn-website/partners/Ever_After_Farms_sthblx.jpg",
  PARTNER_CAMINO_REAL_RANCH_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075770/indemn-website/partners/Camino_Real_Ranch_drhhnx.png",
  PARTNER_EAST_FORK_COUNTRY_ESTATE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729075770/indemn-website/partners/East_Fork_Country_Estate_d59fcn.png",
  PARTNER_FOUNTAINVIEW_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731904732/indemn-website/partners/Fountainview_dtrn8b.png",
  TEAM_KYLE_GEOGHAN_PROFILE_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729078393/indemn-website/team/kyle_profile_pic_lt0bvx.png",
  TEAM_PATRICK_DALY_PROFILE_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729078394/indemn-website/team/patrick_profile_pic_osb2k2.png",
  TEAM_MITCH_BUSSE_PROFILE_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731854230/indemn-website/team/mitchell_pic_v1_jwufd5.png",
  OTHER_404_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729078696/indemn-website/other/404_fqdr1x.png",
  OTHER_MOBILE_OPTIMIZED_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729081433/indemn-website/other/mobile_optimized_kp1iil.png",
  OTHER_LANDING_PAGES_FLAT_IMAGE:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729081433/indemn-website/other/landing_pages_flat_tmxn7l.png",
  MARKEL_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731902664/indemn-website/other/markel_logo_t1hnns.png",
  MARKEL_DARK_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731902661/indemn-website/other/markel_logo_w_ywhirl.png",
  MARKEL_GRADIENT:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731903012/indemn-website/other/markelgradient_zxcmra.png",
};

export const CLOUDINARY_INDEMN_LOGOS = {
  INDEMN_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729078696/indemn-website/other/logo_nydyol.svg",
  INDEMN_DARK_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729078695/indemn-website/other/logo_dark_wezvv2.svg",
  SOLUTIONS_BROKER_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079044/indemn-website/solutions/broker_ioydhw.svg",
  SOLUTIONS_CSR_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079044/indemn-website/solutions/csr_g7rkeg.svg",
  SOLUTIONS_FOR_AGENCIES_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079045/indemn-website/solutions/for-agencies_xbuee2.svg",
  SOLUTIONS_FOR_LARGER_AGENCY_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079046/indemn-website/solutions/for-largers_t4vqmq.svg",
  SOLUTIONS_FOR_MGAS_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079047/indemn-website/solutions/for-mgas_idrir7.svg",
  SOLUTIONS_LEAD_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079048/indemn-website/solutions/lead_yd8qpu.svg",
  SOLUTIONS_QUOTE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729079049/indemn-website/solutions/quote_vm17e7.svg",
  PRODUCT_DISTRIBUTE_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729080515/indemn-website/products/distribute_njox6o.svg",
  PRODUCT_COPILOT_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729080513/indemn-website/products/copilot_vnblnm.svg",
  PRODUCT_ACQUISITION_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729080512/indemn-website/products/acquisition_chmfcc.svg",
  ICON_FEATURE_MAGIC_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729081602/indemn-website/icons/feature-magic_g7kv4u.svg",
  ICON_FEATURE_COMPLIANT_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729081601/indemn-website/icons/feature-compliant_qxxaun.svg",
  ICON_FEATURE_AI_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/q_auto,f_auto/v1729081600/indemn-website/icons/feature-ai_n6clic.svg",
  EVENTGUARD_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731901578/indemn-website/eventguard/logo_eventguard_zftwmb.svg",
  EVENTGUARD_DARK_LOGO:
    "https://res.cloudinary.com/deksgbacz/image/upload/v1731902306/indemn-website/eventguard/logo_dark_pjjw4a.svg",
};

export const PAGE_CONTENT = {
  ABOUT_US: {
    TITLE: "About Us | Indemn Insurance",
    DESCRIPTION:
      "Learn more about Indemn Insurance and our mission to create insurance experiences that people love to use.",
    OG_IMAGE: CLOUDINARY_IMAGES.ABOUT_HERO_IMAGE,
    KEYWORDS:
      "about Indemn Insurance, AI-powered insurance, conversational AI, Generative AI insurance, insurance technology, insurtech, insurance sales automation",
  },
  DATA_PRIVACY: {
    TITLE: "Data Privacy | Indemn Insurance",
    DESCRIPTION:
      "Founded as an insurance company, we take data security seriously and don’t share or sell any personal identifying information.",
    OG_IMAGE: CLOUDINARY_IMAGES.ABOUT_HERO_IMAGE,
    KEYWORDS:
      "data security insurance, insurance data privacy, secure insurance platforms, privacy-focused insurance, personal data protection insurance",
  },
  HOME_PAGE: {
    TITLE: "Indemn Insurance | Self-Driven Insurance Experiences",
    DESCRIPTION:
      "Indemn Insurance is on a mission to create self-driven insurance experiences that people love to use. Learn more about our AI-powered chatbots.",
    OG_IMAGE: CLOUDINARY_IMAGES.HOME_HERO_IMAGE,
    KEYWORDS:
      "self-driven insurance, AI insurance agents, insurance automation, AI insurance chatbots, Generative AI insurance platforms, insurtech innovation",
  },
  NOT_FOUND: {
    TITLE: "404 Not Found | Indemn Insurance",
    DESCRIPTION:
      "The page you are looking for does not exist. Please check the URL and try again.",
    OG_IMAGE: CLOUDINARY_IMAGES.OTHER_404_IMAGE,
    KEYWORDS: "Indemn Insurance",
  },
  PARTNERS: {
    TITLE: "Partners | Indemn Insurance",
    DESCRIPTION:
      "Indemn Insurance partners with the best venues to provide insurance solutions for weddings and events. Learn more about our partners.",
    OG_IMAGE: CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE,
    KEYWORDS:
      "insurance partnerships, venue insurance partners, event insurance partnerships, Indemn Insurance partners, insurance for weddings and events",
  },
  PRODUCTS_ACQUISITION: {
    TITLE: "AI Studio | Indemn Insurance",
    DESCRIPTION: "Build the future of insurance with our next-gen AI Studio",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.PRODUCT_ACQUISITION_LOGO,
    KEYWORDS:
      "AI Studio for insurance, AI insurance solutions, future of insurance AI, AI-driven insurance services, Generative AI insurance development",
  },
  PRODUCTS_DISTRIBUTE: {
    TITLE: "Distribute | Indemn Insurance",
    DESCRIPTION:
      "Indemn Insurance offers distribution solutions for insurance agencies, MGAs, and CSR teams. Learn more about our distribution solutions.",
    OG_IMAGE: CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE,
    KEYWORDS:
      "insurance distribution solutions, MGA insurance solutions, CSR insurance tools, AI-powered insurance distribution, Indemn Insurance for agencies",
  },
  PRODUCTS_COPILOT: {
    TITLE: "Agent Copilot | Indemn Insurance",
    DESCRIPTION: "Humans + AI: The Future of Insurance",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.PRODUCT_COPILOT_LOGO,
    KEYWORDS:
      "Agent Copilot, AI insurance assistant, AI copilot for insurance agents, insurance automation tools, human-AI collaboration insurance",
  },
  SOLUTIONS_AGENCIES: {
    TITLE: "Solutions for Agencies | Indemn Insurance",
    DESCRIPTION:
      "Indemn Insurance provides AI-powered solutions for insurance agencies to improve customer service and increase sales. Learn more about our solutions.",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_FOR_AGENCIES_LOGO,
    KEYWORDS:
      "AI solutions for insurance agencies, insurance agency automation, AI-powered insurance tools, insurance sales enhancement, Indemn Insurance for agencies",
  },
  SOLUTIONS_BROKER: {
    TITLE: "Broker Portal Assistant | Indemn Insurance",
    DESCRIPTION:
      "Save time by rapidly answering 50% of all service requests from your appointed agents",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_BROKER_LOGO,
    KEYWORDS:
      "broker portal automation, AI broker portal assistant, insurance broker support tools, AI-powered broker solutions, insurance broker workflow automation",
  },
  SOLUTIONS_CSR: {
    TITLE: "AI CSRs | Indemn Insurance",
    DESCRIPTION:
      "Deploy multilingual AI agents across channels, available 24/7 and improving every day",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_CSR_LOGO,
    KEYWORDS:
      "AI-powered CSR, multilingual AI CSRs, AI insurance customer service, 24/7 insurance AI, AI solutions for insurance customer service",
  },
  SOLUTIONS_ENTERPRISE: {
    TITLE: "Solutions for Large Enterprises | Indemn Insurance",
    DESCRIPTION:
      "Indemn Insurance provides AI-powered solutions for large enterprises to improve customer service and increase sales. Learn more about our solutions.",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_FOR_LARGER_AGENCY_LOGO,
    KEYWORDS:
      "enterprise insurance solutions, AI for large enterprises, AI-powered insurance tools, insurance sales automation, Indemn Insurance for enterprises",
  },
  SOLUTIONS_MGAS: {
    TITLE: "Solutions for MGAs | Indemn Insurance",
    DESCRIPTION:
      "Indemn Insurance provides AI-powered solutions for MGAs to improve customer service and increase sales. Learn more about our solutions.",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_FOR_MGAS_LOGO,
    KEYWORDS:
      "MGA insurance solutions, AI-powered MGA tools, MGA insurance automation, insurance AI for MGAs, Indemn Insurance MGA services",
  },
  SOLUTIONS_LEAD: {
    TITLE: "Lead Capture and Workflow Agents | Indemn Insurance",
    DESCRIPTION:
      "Convert more users, get insurance applications completed, renew and round out accounts",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_LEAD_LOGO,
    KEYWORDS:
      "lead capture insurance, insurance workflow automation, AI lead capture for insurance, insurance application AI, insurance conversion tools",
  },
  SOLUTIONS_QUOTE: {
    TITLE: "Online Quotes, Underwriting, and Payments | Indemn Insurance",
    DESCRIPTION:
      "The full digital distribution stack to replace your webform and drive 10X more online purchases",
    OG_IMAGE: CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_QUOTE_LOGO,
    KEYWORDS:
      "online insurance quotes, AI insurance underwriting, AI-powered insurance payments, digital insurance sales, insurance e-commerce solutions",
  },
};
