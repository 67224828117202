import React from "react";
import { Box } from "@mui/material";
import * as constants from "utils/constants";

interface LogoProps {
  isDark?: boolean;
  width?: number;
}

export default function Logo({ isDark = false, width = 200 }: LogoProps) {
  return (
    <Box component="a" href="/" sx={{ textDecoration: "none" }}>
      <Box
        component="img"
        src={
          isDark
            ? constants.CLOUDINARY_INDEMN_LOGOS.EVENTGUARD_DARK_LOGO
            : constants.CLOUDINARY_INDEMN_LOGOS.EVENTGUARD_LOGO
        }
        alt="Indemn"
        width={width}
      />
    </Box>
  );
}
