import { createTheme, Theme } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';
import typography from './typography';
import { hexToHsl } from 'utils/helper-color';

const getTheme = (mode: string, themeToggler: () => void): Theme =>
  createTheme({
    palette: mode === 'light' ? light : dark,
    shadows: shadows(),
    typography,
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 544,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: 'none',
            whiteSpace: 'nowrap',
          },
          contained: ({ theme, ownerState }) => {
            let background, color, border;
            switch (ownerState.color) {
              case 'inherit':
                background = 'transparent';
                color = theme.palette.monochrome.gray[600];
                break;
              case 'secondary':
                background = '#ffffff';
                color = theme.palette.primary[900];
                border = `1px solid ${theme.palette.primary[100]}`;
                break;
              default: // primary
                background = theme.palette.primary[800];
                color = '#ffffff';
                border = `1px solid ${theme.palette.primary[400]}`;
            }

            const boxShadow = `0.2px 0.2px 0.3px ${hexToHsl(background, 0.34)},
            0.6px 0.6px 1px -0.7px ${hexToHsl(background, 0.38)},
            1.5px 1.5px 2.5px -1.3px ${hexToHsl(background, 0.42)},
            3.6px 3.5px 6px -2px ${hexToHsl(background, 0.08)}`;

            return {
              border,
              color,
              background,
              transition: 'unset',
              '&:hover': {
                boxShadow,
                background,
              },
              '&:active': {
                boxShadow,
                background,
              },
              '&:focus': {
                outlineOffset: 2,
                outline: `1px dashed ${theme.palette.primary[800]}`,
              },
              '&:disabled': {
                color: theme.palette.monochrome.gray[400],
                border: `1px solid ${theme.palette.monochrome.gray[300]}`,
                background: theme.palette.monochrome.gray[200]
              }
            };
          },
          outlined: ({ theme, ownerState }) => {
            let color, border;
            switch (ownerState.color) {
              case 'inherit':
                color = theme.palette.monochrome.gray[600];
                border = `1px solid ${theme.palette.monochrome.gray[400]}`;
                break;
              default: // primary
                color = theme.palette.primary[600];
                border = `1px solid ${theme.palette.primary[600]}`;
            }

            return {
              border,
              color,
              background: 'transparent',
              '&:hover': {
                border,
                background: 'rgba(156, 163, 175, 0.33)'
              },
              '&:active': {
                border,
                background: 'rgba(156, 163, 175, 0.87)'
              },
              '&:focus': {
                outlineOffset: 2,
                outline: `1px dashed ${theme.palette.primary[800]}`,
              },
              '&:disabled': {
                color: theme.palette.monochrome.gray[300],
                border: `1px solid ${theme.palette.monochrome.gray[300]}`,
              }
            };
          },
          text: ({ theme, ownerState }) => {
            let color;
            switch (ownerState.color) {
              case 'inherit':
                color = theme.palette.monochrome.gray[600];
                break;
              default: // primary
                color = theme.palette.primary[800];
            }

            return {
              color,
              '&:hover': {
                color: theme.palette.monochrome.gray[600],
                background: 'rgba(156, 163, 175, 0.33)'
              },
              '&:active': {
                color: theme.palette.monochrome.gray[600],
                background: 'rgba(156, 163, 175, 0.87)',
              },
              '&:focus': {
                background: 'rgba(156, 163, 175, 0.33)',
                // outlineOffset: 2,
                // outline: `1px dashed ${theme.palette.primary[800]}`,
              },
              '&:disabled': {
                color: theme.palette.monochrome.gray[300]
              }
            };
          },
          sizeLarge: ({ theme }) => ({
            padding: '12px 20px',
            ...theme.typography.lg,
            ...theme.typography.medium,
            '& svg': {
              width: 24,
              height: 24
            }
          }),
          sizeMedium: ({ theme }) => ({
            padding: '8px 16px',
            ...theme.typography.base,
            ...theme.typography.medium,
            '& svg': {
              width: 20,
              height: 20
            }
          }),
          sizeSmall: ({ theme }) => ({
            padding: '6px 12px',
            ...theme.typography.sm,
            ...theme.typography.medium,
            '& svg': {
              width: 16,
              height: 16
            }
          }),
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: '12px 20px',
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
    themeToggler,
  });

export default getTheme;
