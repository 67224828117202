import React from "react";
import {
  About as AboutView,
  PartnerWithUs as PartnerWithUsView,
  Partner as PartnerView,
  Venue as VenueView,
  NotFound as NotFoundView,
  GetInsurance as GetInsuranceView,
  Weddings as WeddingView,
} from "views";

// ensure wedding view is live in prod

const routes = [
  {
    path: "/",
    renderer: (params = {}): JSX.Element => <WeddingView {...params} />,
  },
  {
    path: "/weddings",
    renderer: (params = {}): JSX.Element => <WeddingView {...params} />,
  },
  {
    path: "/partners/:slug",
    renderer: (params = {}): JSX.Element => <PartnerView {...params} />,
  },
  {
    path: "/venues/:slug",
    renderer: (params = {}): JSX.Element => <VenueView {...params} />,
  },
  {
    path: "/about",
    renderer: (params = {}): JSX.Element => <AboutView {...params} />,
  },
  {
    path: "/partner-with-us",
    renderer: (params = {}): JSX.Element => <PartnerWithUsView {...params} />,
  },
  {
    path: "/get-insurance/:slug",
    renderer: (params = {}): JSX.Element => <GetInsuranceView {...params} />,
  },
  {
    path: "/weddings",
    renderer: (params = {}): JSX.Element => <GetInsuranceView {...params} />,
  },
  {
    path: "/not-found",
    renderer: (params = {}): JSX.Element => <NotFoundView {...params} />,
  },
];

export default routes;
