import React, { useEffect, useRef, useState } from "react";

interface ChatLoaderProps {
  scriptUrl?: string;
  elementId?: string;
}

const ChatLoader: React.FC<ChatLoaderProps> = ({
  scriptUrl = "https://devproxy.indemn.ai/chat-script/676be5a7ab56400012077e7d.js", // Default URL
  elementId = "indemn-webchat",
}) => {
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if the element already exists in the DOM
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_CHATBOT_SCRIPT_URL || scriptUrl;
    script.type = "text/javascript";
    script.defer = true;

    if (chatRef.current) {
      chatRef.current.insertAdjacentElement("afterend", script);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script); // Clean up the script
      }
    };
  }, [scriptUrl]);

  // Return the div only if the element doesn't exist in the DOM
  return document.getElementById(elementId) ? null : (
    <div ref={chatRef} id={elementId} style={{ height: "100%" }} />
  );
};

export default ChatLoader;
