import React, { useContext } from "react";
import { Container, GridContainer } from "components";
import { ChatbotContext } from "context/chatbot-state";
import { BoxProps } from "@mui/material";

const BotHostContainer = (boxProps: BoxProps) => {
  const chatbotCtx = useContext(ChatbotContext);
  const { isChatOpen } = chatbotCtx.chatbotState;

  return (
    <GridContainer pb={isChatOpen ? { xs: 12, md: 16 } : 0} {...boxProps}>
      <Container
        id="indemn-webchat"
        height={isChatOpen ? "80vh" : 0}
        px={0}
        py={isChatOpen ? { xs: 1.5, md: 2, xl: 2.5 } : 0}
      />
    </GridContainer>
  );
};

export default BotHostContainer;
