import React from 'react';
import { Button, useTheme, Menu, BoxProps } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Container from 'components/container';

interface Props {
  children?: React.ReactNode;
  label: string;
}

const NavMenu = ({ label, children, ...boxProps }: Props & BoxProps): JSX.Element => {
  const { typography, palette, shadows, zIndex } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant='text'
        endIcon={<FeatherIcon icon={open ? 'chevron-up' : 'chevron-down'} />}
        size='medium'
        onClick={handleClick}
        sx={{ ...typography.sm }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: zIndex.appBar + 1,
          '& .MuiMenu-paper': {
            background: palette.background.navDefault,
            borderRadius: '12px',
            boxShadow: shadows[1],
            minWidth: 320,
            maxWidth: 420
          },
          '& ul': {
            p: 0
          }
        }}
      >
        <Container display="flex" flexDirection="column" alignItems="baseline" gap={2} {...boxProps}>
          {children}
        </Container>
      </Menu>
    </>
  );
};

export default NavMenu;
