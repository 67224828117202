import React from 'react';
import Box from '@mui/material/Box';

interface Props {
  src: string;
  alt?: string;
  imageProps?: {
    width?: string | number;
    height?: string | number;
    // All other props
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  };
  // All other props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Image = ({
  src,
  alt,
  imageProps = {},
  ...rest
}: Props): JSX.Element => {
  return (
    <Box
      className={'image'}
      {...rest}
    >
      <img
        src={src}
        alt={alt}
        loading={'lazy'}
        width={'100%'}
        height={'100%'}
        {...imageProps}
      />
    </Box>
  );
};

export default Image;
