import React from 'react';
import { Box } from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

interface Props {
  id: string;
}

const CloudImage = ({ id }: Props): JSX.Element => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'deksgbacz',
    },
  });

  return (
    <Box sx={{ '& img': { width: 1 } }}>
      <AdvancedImage cldImg={cld.image(id)} />
    </Box>
  );
};

export default CloudImage;
