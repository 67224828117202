// ChatbotStoreProvider.tsx
import React, { createContext, useState } from "react";

// Define the new state type for `askIndemnButton`
type ChatbotState = {
  isAskIndemnActive: boolean;
};

const initialState = {
  isAskIndemnActive: false, // Initial state for `askIndemnButton`
};

export const ChatbotContextLatest = createContext<{
  chatbotState: ChatbotState;
  setAskIndemnActive: (active: boolean) => void;
}>({
  chatbotState: initialState,
  setAskIndemnActive: () => undefined, // Default function to avoid lint warning
});

export const ChatbotStoreProviderLatest: React.FC = ({ children }) => {
  const [isAskIndemnActive, setAskIndemnActive] = useState(false);

  const chatbotState = {
    isAskIndemnActive,
  };

  return (
    <ChatbotContextLatest.Provider
      value={{
        chatbotState,
        setAskIndemnActive,
      }}
    >
      {children}
    </ChatbotContextLatest.Provider>
  );
};
