import React from "react";
import Main from "layouts/main";
import { ChatbotStoreProvider } from "context/chatbot-state";
import Hero from "./hero";
import Partners from "./partners";
import Features from "./features";
import Products from "./products";
import TwoColumnCard from "blocks/TwoColumnCard";

const Home = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        <Hero />
        <TwoColumnCard />
        {/* venue planners to go to page redirect */}
        {/* get insurance to go to bot page */}
        <Features />
        <Partners />
        <Products />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Home;
