import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import viewsRoutes from 'views/routes';
import redirects from 'config/301-redirects.json';

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      {Object.keys(redirects).map(oldPath => (
        <Route key={oldPath} path={oldPath} element={<Navigate to={redirects[oldPath]} />} />
      ))}
      {viewsRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </ReactRoutes>
  );
};

export default Routes;
