import React, { useEffect, useContext } from "react";
import { GridContainer, MarkDown } from "components";
import { useTheme, Box } from "@mui/material";
import { ChatbotContext, chatOpen } from "context/chatbot-state";
import FeatherIcon from "feather-icons-react";
import AskIndmenButton from "views/components/ask-indemn-button";
import { Faq } from "views/components";
import SocialProofCarousel from "../../blocks/SocialProofCarousel";
// import { useFAQ } from 'hooks/use-faq';

const Display = (): JSX.Element => {
  const { typography } = useTheme();
  const chatbotCtx = useContext(ChatbotContext);
  const { isChatbotConnected, isChatOpen } = chatbotCtx.chatbotState;

  useEffect(() => {
    if (isChatbotConnected && chatOpen && !isChatOpen) {
      chatOpen();
    }
  }, [isChatbotConnected]);

  //   const { typography, palette } = useTheme();
  const faqs = [
    {
      faq_intent: "what_is_liquor_liability",
      question: "What is liquor liability?",
    },
    { faq_intent: "what_is_cancellation", question: "What is cancellation?" },
    { faq_intent: "how_to_purchase", question: "How do I purchase?" },
    { faq_intent: "when_to_buy", question: "When should I buy?" },
    {
      faq_intent: "how_long_does_application_take",
      question: "How long does it take?",
    },
    { faq_intent: "what_is_refund_policy", question: "How do refunds work?" },
  ];

  return (
    <div>
      <GridContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "12px",
            border: "1px solid rgba(255, 255, 255, 0.32)",
            background:
              "linear-gradient(155deg, rgba(255, 255, 255, 0.94) 36.16%, rgba(255, 255, 255, 0.20) 85.22%)",
            backdropFilter: "blur(32px)",
            width: { xs: 1, md: 6 / 8, xl: 6 / 12 },
            margin: "0 auto",
            padding: { xs: "36px 16px", md: "36px 24px" },
          }}
        >
          <MarkDown
            sx={{ ...typography["3xl"], ...typography.bold, mb: 2 }}
            text={"The best way to buy wedding insurance"}
          />

          <MarkDown
            sx={{ ...typography.base, ...typography.regular, mb: 4.5 }}
            text="For all weddings and event coverages"
          />

          <AskIndmenButton
            variant="contained"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
          >
            Get Insurance
          </AskIndmenButton>
          <AskIndmenButton
            variant="contained"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
            style={{ backgroundColor: "#34C759", color: "#fff" }}
          >
            Venue Planners and Platforms
          </AskIndmenButton>
        </Box>
      </GridContainer>
      <SocialProofCarousel />
      <Faq faqs={faqs} />
    </div>
  );
};

export default Display;
