import React from 'react';
import { Box } from '@mui/material';
import { GridContainer, Image } from 'components';

const investors = [
  { src: '/images/investors/afterwork_logo.png', alt: 'Afterwork Ventures' },
  { src: '/images/investors/the_fund_logo.png', alt: 'The Fund' },
  { src: '/images/investors/markd_logo.png', alt: 'Markd' },
];

const Investors = () => (
  <GridContainer id='investors'>
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr' }}
      gap={{ xs: 0.5, md: 4.5, xl: 6.5 }}
    >
      {investors.map((investor, index) => (
        <Image
          key={index}
          src={investor.src}
          alt={investor.alt}
          width={{ xs: 180, md: 1 }}
          height={{ xs: 96, md: 160 }}
          justifySelf="center"
          imageProps={{
            style: {
              objectFit: 'contain',
              mixBlendMode: 'darken',

            }
          }}
        />
      ))}
    </Box>
  </GridContainer>
);

export default Investors;
