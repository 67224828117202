import React, {
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
} from "react";

interface ChatbotState {
  isAskIndemnActive: boolean;
  isChatOpen: boolean;
  isChatbotConnected: boolean;
}

interface ChatbotContextValue {
  chatbotState: ChatbotState;
  setAskIndemnActive: (active: boolean) => void;
  setChatOpen: (open: boolean) => void;
  setChatbotConnected: (connected: boolean) => void;
}

const initialState: ChatbotState = {
  isAskIndemnActive: false,
  isChatOpen: false,
  isChatbotConnected: false,
};

export const ChatbotContextLatest = createContext<ChatbotContextValue>({
  chatbotState: initialState,
  setAskIndemnActive: () => undefined,
  setChatOpen: () => undefined,
  setChatbotConnected: () => undefined,
});

export const ChatbotStoreProviderLatest: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isAskIndemnActive, setAskIndemnActive] = useState(false);
  const [isChatOpen, setChatOpen] = useState(false);
  const [isChatbotConnected, setChatbotConnected] = useState(false);

  const handleSetAskIndemnActive = useCallback((active: boolean) => {
    setAskIndemnActive(active);
  }, []);

  const handleSetChatOpen = useCallback((open: boolean) => {
    setChatOpen(open);
  }, []);

  const handleSetChatbotConnected = useCallback((connected: boolean) => {
    setChatbotConnected(connected);
  }, []);

  const chatbotState = {
    isAskIndemnActive,
    isChatOpen,
    isChatbotConnected,
  };

  return (
    <ChatbotContextLatest.Provider
      value={{
        chatbotState,
        setAskIndemnActive: handleSetAskIndemnActive,
        setChatOpen: handleSetChatOpen,
        setChatbotConnected: handleSetChatbotConnected,
      }}
    >
      {children}
    </ChatbotContextLatest.Provider>
  );
};
