import React from 'react';
import { Box, useTheme } from '@mui/material';
import Topbar from './topbar';
import Footer from './footer';
import Image from 'components/image';

interface Props {
  children: React.ReactNode;
  isExtended?: boolean;
}

const Main = ({ children, isExtended }: Props): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Box sx={{ background: palette.background.bodyPrimary, position: 'relative', overflow: 'hidden' }}>
      <Topbar />
      <main style={{ position: 'relative' }}>
        {/* hue background */}
        <Image
          src="/images/heroes/hue.png"
          alt=""
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh'
          }}
          imageProps={{
            style: {
              filter: 'blur(32px)',
              objectFit: 'cover',
              mixBlendMode: 'hue',
            }
          }}
        />
        {children}
      </main>
      <Footer isExtended={isExtended} />
    </Box>
  );
};

export default Main;
