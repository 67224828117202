import React from "react";
import { GridContainer, Container, Image, MarkDown } from "components";
import { useTheme, Box } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import AskIndmenButton from "views/components/ask-indemn-button";
import * as constants from "utils/constants";

const Hero = ({ partner }: { partner: Partner }): JSX.Element => {
  const { typography } = useTheme();

  return (
    <>
      <Image
        src={constants.CLOUDINARY_IMAGES.HUE_HERO_IMAGE}
        alt="hero-background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
        imageProps={{
          style: {
            filter: "blur(32px)",
            objectFit: "cover",
            mixBlendMode: "hue",
          },
        }}
      />
      {/* hero background */}
      <Image
        src={constants.CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE}
        alt="partner-us-hero-image"
        sx={{
          position: "absolute",
          width: { xs: "116vh", md: "112vh", xl: "140vh" },
          height: { xs: "116vh", md: "112vh", xl: "140vh" },
          right: { xl: "-37.5vh" },
          top: { xs: "42vh", md: "12vh", xl: "-35vh" },
        }}
        imageProps={{
          style: {
            objectFit: "cover",
            mixBlendMode: "darken",
            opacity: 0.8,
          },
        }}
      />

      <GridContainer
        sx={{
          display: "flex",
          flexDirection: { xs: "column", xl: "row" },
          justifyContent: "space-between",
          mt: 16,
        }}
      >
        <Container
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
        >
          {!!partner.logo_img && (
            <Image
              src={partner.logo_img}
              alt={partner.partner_name}
              maxWidth={180}
              width={1}
              imageProps={{
                style: {
                  objectFit: "contain",
                },
              }}
            />
          )}
          <MarkDown
            sx={{ ...typography["4xl"], ...typography.bold }}
            text={partner.hero_heading ?? "Wedding insurance made easy"}
          />
          <MarkDown
            sx={{ ...typography.lg, ...typography.regular }}
            text={
              partner.hero_body ??
              "Get the perfect coverage in minutes and at the best value through an intuitive and automated self-serve experience."
            }
          />
          <AskIndmenButton
            variant="contained"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
            sx={{ width: "fit-content" }}
          >
            {partner.cta_label ?? "Get quote"}
          </AskIndmenButton>
        </Container>
        {!!partner.hero_img && (
          <Box
            width={{ xs: 1, md: 6 / 8, xl: 5 / 12 }}
            alignSelf="flex-end"
            mt={{ xs: 4, md: 6, xl: 0 }}
          >
            <Image
              src={partner.hero_img}
              height={{ xs: "42vh", md: "36vh", xl: "50vh" }}
              imageProps={{
                style: {
                  objectFit: "cover",
                  opacity: 0.88,
                  borderRadius: 12,
                  width: "150%",
                },
              }}
            />
          </Box>
        )}
      </GridContainer>
    </>
  );
};

export default Hero;
