import React from "react";
import Main from "layouts/main";
import Hero from "./hero";
import { useTheme, Typography } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { GridContainer, Container } from "components";
import {
  ProgressCarousel,
  BotHostContainerLatest,
  AskIndmenButtonLatest,
} from "views/components";
import VenuesGrid from "blocks/VenuesGrid";
import { ChatbotStoreProviderLatest } from "context/chatbot-state-latest";
import ChatLoader from "context/chatLoader";

// const topTestmonials = [
//   {
//     recommendation:
//       "“Posey Meadows has been very happy to partner with EventGuard insurance. Our clients appreciate being sent a link that is easy and quick to fill out and then we appreciate the quick turnaround with which EventGuard sends the COI to us. It's a great system for busy venue owners.”",
//     image: "/images/partners/Posey Meadows.png",
//     name: "Julie Kate Campbell",
//     role: "Owner at Posey Meadows",
//     href: "https://poseymeadows.com/",
//   },
//   {
//     recommendation:
//       "“EventGuard has changed the game for us! Our days of chasing clients down to purchase (and turn in) their wedding insurance are gone. Their services offer our clients a fast and convenient way to research and purchase wedding insurance that meet our requirements. Even better, it streamlines the process to where we receive a copy of their policy to our inbox as soon as it’s purchased. Thank you to Indemn and their entire team for making this part of the wedding planning experience EASY!”",
//     image: "/images/partners/Madera Estates.png",
//     name: "Ian Ramirez",
//     role: "Madera Estates",
//     href: "https://maderaestates.com/",
//   },
// ];

const bottomTestmonials = [
  {
    recommendation:
      "“Posey Meadows has been very happy to partner with EventGuard insurance. Our clients appreciate being sent a link that is easy and quick to fill out and then we appreciate the quick turnaround with which EventGuard sends the COI to us. It's a great system for busy venue owners.”",
    image: "/images/partners/Posey Meadows.png",
    name: "Julie Kate Campbell",
    role: "Owner at Posey Meadows",
    href: "https://poseymeadows.com/",
  },
  {
    recommendation:
      "“EventGuard has changed the game for us! Our days of chasing clients down to purchase (and turn in) their wedding insurance are gone. Their services offer our clients a fast and convenient way to research and purchase wedding insurance that meet our requirements. Even better, it streamlines the process to where we receive a copy of their policy to our inbox as soon as it’s purchased. Thank you to EventGuard and their entire team for making this part of the wedding planning experience EASY!”",
    image: "/images/partners/Madera Estates.png",
    name: "Ian Ramirez",
    role: "Madera Estates",
    href: "https://maderaestates.com/",
  },
  {
    recommendation:
      "“We could not be happier with EventGuard. They have made it incredibly easy for our clients to get the insurance required for their venues. They always take the time to answer the questions I have as venue manager and event planner, and trust me I ALWAYS have questions. I truly am so grateful for the insurance they provide as well as the standards they hold.”",
    image: "/images/partners/Fountainview.png",
    name: "Carley E.",
    role: "Venue Director at Fountainview Mansion",
    href: "https://www.shadybrookacres.com/",
  },
  {
    recommendation:
      "“.. I have found that finding good partners to work with makes all the difference in maintaining sanity and making your business successful. I'm happy to say that working with EventGuard has been a value added for our wedding venue - they save me time and I'm sure our couples are covered on their big day..”",
    image: "/images/partners/Shady Brook Acres Hitching Post.png",
    name: "Anne Reed",
    role: "Owner at Shady Brook Acres Hitching Post",
    href: "https://www.shadybrookacres.com/",
  },
  {
    recommendation:
      "“We appreciate how much EventGuard values a personal relationship with their venues which is not what we have experienced with other event insurance providers! They make the process simple and straightforward for our couples.”",
    image: "/images/partners/Camino Real Ranch.png",
    name: "Jenn Valenza",
    role: "Owner at Camino Real Ranch",
    size: "large",
    href: "https://www.caminorealranch.com/",
  },
  {
    recommendation:
      "“EventGuard has been a wonderful resource for our clients/families. I thank you for your responsiveness and your product. It seems to match up to the needs of our couples/families who want to cover their wedding day. I look forward to working with you a lot in the future.”",
    image: "/images/partners/Old_Wide_Awake_Plantation.png",
    name: "Margaret McKenzie",
    role: "Event co-ordinator at Old Wide Awake Plantation",
    href: "https://oldwideawakeplantation.com/",
  },
  // {
  //   recommendation: '“Indemn has streamlined our insurance requirements for our couples. The custom page provided with our requirements makes it very easy for our couples to click through a few questions and they are finished. It has reduced correspondence which has saved time.”',
  //   image: '/images/partners/Peacock Ridge.png',
  //   name: 'Cindy Lowry',
  //   role: 'Owner at Peacock Ridge',
  //   href: 'https://www.peacockridge.com/'
  // },
  {
    recommendation:
      "“EventGuard has made a huge difference for our clients and our company. They created a tailored conversational interface specifically for our clients to access and receive a quote for wedding insurance right on the spot. What an awesome tool. It’s so simple and easy to use and the cost of insurance is super affordable that we are no longer having to chase clients down for proof of insurance. Thank you EventGuard for reaching out and setting this up for us.”",
    image: "/images/partners/Hawkesdene.png",
    name: "Rob Scheiwiller",
    role: "Owner at Hawkesdene",
    href: "https://www.hawkesdene.com/",
  },
  //{
  //   recommendation: '“Great Process! Makes it so easy for renters and for the venue. I always receive positive feedback from the renters. Indemn have made this so easy. Prior to using, it was such an ordeal to get renters to complete the process of purchasing Event Insurance.”',
  //   image: '/images/partners/Knoxville Botanical Garden and Arboretum.png',
  //   name: 'JeanneLove Ferguson',
  //   role: 'Special Projects and Events Manager at Knoxville Botanical Garden and Arboretum',
  //   href: 'https://www.knoxgarden.org/'
  // },
  {
    recommendation:
      "“I love EventGuard! They have been a pleasure to work with. We own a wedding venue and they provided us with a link to pass on to our brides that not only makes us look professional but also provides our brides with an amazing event insurance at a very reasonable price!”",
    image: "/images/partners/Sea Pearl Beach House Weddings.png",
    name: "PJ Willoughby-Chambers",
    role: "Sea Pearl Beach House Weddings",
    href: "https://www.theknot.com/marketplace/st-augustines-sea-pearl-beach-house-weddings-st-augustine-fl-2026152",
  },
  {
    recommendation:
      "“EventGuard have been awesome to work with! They created a direct link for our clients to use for event insurance which has proven to be very convenient. We highly recommend this company!”",
    image: "/images/partners/The_School_House.png",
    name: "Eliza Kenyon",
    role: "Lead coordinator at The School House Venue",
    href: "https://theschoolhousevenue.com/",
  },
];

// const partners = [
//   {
//     icon: "/images/partners/Ever After Farms.png",
//     name: "Ever After Farms",
//     description: "7 venues across Florida",
//     href: "https://www.everafterfarms.com/",
//   },
//   {
//     icon: "/images/partners/Shady Brook Acres Hitching Post.png",
//     name: "Shady Brook Acres Hitching Post",
//     description: "Napoleon, OH",
//     href: "https://www.shadybrookacres.com/",
//   },
//   {
//     icon: "/images/partners/public/Old_Wide_Awake_Plantation.png",
//     name: "Old Wide Awake Plantation",
//     description: "Hollywood, SC",
//     href: "https://oldwideawakeplantation.com/",
//   },
//   {
//     icon: "/images/partners/Peacock Ridge.png",
//     name: "Peacock Ridge",
//     description: "Lawrence, OH",
//     href: "https://www.peacockridge.com/",
//   },
//   {
//     icon: "/images/partners/Hawkesdene.png",
//     name: "Hawkesdene",
//     description: "Andrews, NC",
//     href: "https://www.hawkesdene.com/",
//   },
//   {
//     icon: "/images/partners/Camino Real Ranch.png",
//     name: "Camino Real Ranch",
//     description: "Austin, TX",
//     size: "large",
//     href: "https://www.caminorealranch.com/",
//   },
//   {
//     icon: "/images/partners/East Fork Country Estate.png",
//     name: "East Fork Country Estate",
//     description: "Damascus, OR",
//     href: "https://eastforkcountryestate.com/",
//   },
//   {
//     icon: "/images/partners/Knoxville Botanical Garden and Arboretum.png",
//     name: "Knoxville Botanical Garden and Arboretum",
//     description: "Knoxville, TN",
//     href: "https://www.knoxgarden.org/",
//   },
//   {
//     icon: "/images/partners/The Paseo.png",
//     name: "The Paseo",
//     description: "Apache Junction, AZ",
//     href: "https://thepaseovenue.com/",
//   },
//   {
//     icon: "/images/partners/Stonegate Manor.png",
//     name: "Stonegate Manor",
//     description: "Harbor, MI",
//     href: "https://www.stonegatemanorevents.com/",
//   },
//   {
//     icon: "/images/partners/The Ruins at Sassafras.png",
//     name: "The Ruins at Sassafras",
//     description: "New Lebanon, NY",
//     href: "https://www.sassafrasruins.com/",
//   },
//   {
//     icon: "/images/partners/Gainey Vinyard.png",
//     name: "Gainey Vinyard",
//     description: "Santa Ynez, CA",
//     href: "https://gaineyvineyard.com/",
//   },
//   {
//     icon: "/images/partners/The_School_House.png",
//     name: "The School House Venue",
//     description: "Travelers Rest, SC",
//     href: "https://theschoolhousevenue.com/",
//   },
//   {
//     icon: "/images/partners/Madera Estates.png",
//     name: "Madera Estates",
//     description: "Conroe, TX",
//     href: "https://maderaestates.com/",
//   },
//   {
//     icon: "/images/partners/Sea Pearl Beach House Weddings.png",
//     name: "Sea Pearl Beach House Weddings",
//     description: "Ponte Vedra Beach, FL",
//     href: "https://www.theknot.com/marketplace/st-augustines-sea-pearl-beach-house-weddings-st-augustine-fl-2026152",
//   },
// ];

// const features = [
//   {
//     title: "Dedicated landing pages",
//     content:
//       "Each page is equipped with a conversational UI that will take your clients’ breath away",
//   },
//   {
//     title: "Rich, informative customer experience",
//     content:
//       "Answer questions on the spot, enabling a confident, friction-less purchase",
//   },
//   {
//     title: "Real-time monitoring and compliance",
//     content: "Secure the best-in-market compliant policy for your needs",
//   },
// ];

const PartnerWithUs = (): JSX.Element => {
  const { typography } = useTheme();
  return (
    <ChatbotStoreProviderLatest>
      <Main>
        <Hero />
        {/* top - testmonials */}
        <BotHostContainerLatest />

        <GridContainer>
          <Typography
            color="primary.900"
            sx={{ ...typography["4xl"], ...typography.bold, mb: 12 }}
          >
            What our Partners think
          </Typography>
          <ProgressCarousel items={bottomTestmonials} />
        </GridContainer>

        <GridContainer>
          <VenuesGrid testimonials={bottomTestmonials} />
        </GridContainer>

        {/* <GridContainer display="grid" gridTemplateColumns={{ xs: '1f', md: '1fr 1fr', xl: '1fr 1fr 1fr' }} gap={{ xs: 1, md: '16px 36px', xl: '24px 52px' }}>
        {partners.map(partner => <Partner key={partner.name} {...partner} />)}
      </GridContainer> */}

        <GridContainer>
          <Container
            display="flex"
            flexDirection="column"
            gap={4}
            width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography["4xl"], ...typography.bold }}
            >
              Customize your experience
            </Typography>
            <Typography
              variant="h5"
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              EventGuard has built the most customizable self-driven insurance
              platform in the market, backed by glowing reviews from its
              affinity partners spread across the United States.
              <br />
              <br />
              Work with us to deliver coverage important to the next generation,
              on your platform, at lightning speed.
            </Typography>
            <AskIndmenButtonLatest
              variant="contained"
              endIcon={<FeatherIcon icon="message-circle" />}
              size="large"
              sx={{ width: "fit-content" }}
              message="I'm looking to partner with EventGuard"
              // onClick={() =>
              //   window.open(
              //     "https://airtable.com/appZTeA2fGFOS0tNv/shr8QixOFnrItxbVc",
              //     "_blank"
              //   )
              // }
            >
              Partner with EventGuard
            </AskIndmenButtonLatest>
          </Container>
        </GridContainer>
        {/* <Events /> */}
        <ChatLoader />
      </Main>
    </ChatbotStoreProviderLatest>
  );
};

export default PartnerWithUs;
