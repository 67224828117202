import React, { useEffect, useState } from "react";
import Main from "layouts/main";
import { useParams } from "react-router-dom";
import { useFAQ } from "hooks/use-faq";
import { ChatbotStoreProvider } from "context/chatbot-state";
import { BotHostContainer, Faq } from "views/components";
import Loading from "components/loading";
import NotFound from "views/not-found";
import Hero from "./hero";
import { parseAdditionalSlots } from "utils/helper-generic";

const Partner = (): JSX.Element => {
  const { slug } = useParams();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { faqs } = useFAQ(partner?.faq_table, partner?.faq_list);

  useEffect(() => {
    const fetchPartnerBySlug = async () => {
      setLoading(true);

      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/fetch-partner-by-slug`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ slug }),
            }
          )
        ).json();

        setPartner(res.partner);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    if (slug) {
      fetchPartnerBySlug();
    }
  }, [slug]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (!partner) {
    return <NotFound />;
  }

  const payload = `/get_started${JSON.stringify({
    partner_name: partner.partner_name,
    partner_lead: false, // phase this out after checking in Botfront
    partner_type: "ancillary", // phase this out after checking in Botfront
    path_type: "partner",
    package_config: partner.package_config,
    partner_id: partner.slug,
    cancellation_available: partner.cancellation_available,
    first_message: partner.first_message,
    partner_email: partner.partner_email,
    mandate_liability: partner.mandate_liability,
    mandate_cancellation: partner.mandate_cancellation,
    ...parseAdditionalSlots(partner.additional_slots),
  })}`;

  return (
    <ChatbotStoreProvider
      botType={
        process.env.REACT_APP_BOT_TYPE
          ? process.env.REACT_APP_BOT_TYPE
          : partner.bot
      }
      initPayload={payload}
    >
      <Main>
        <Hero partner={partner} />
        <BotHostContainer />
        <Faq faqs={faqs} />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Partner;
