import React from "react";
import { Button } from "@mui/material";
import AskIndmenButton from "../views/components/ask-indemn-button";
import FeatherIcon from "feather-icons-react";
import "./TwoColumnCard.css";
import { Link } from "react-router-dom";

const TwoColumnCard = (props) => {
  return (
    <div className="two-column-card">
      {/* Cancellation Only Card */}
      <div className="two-column-card-box card-cancellation">
        <h3>Purchase for an event</h3>
        <div className="card-price">Get Quote</div>
        <div className="card-onwards">Purchase within 3 minutes</div>

        <ul className="card-features">
          <li className="success">
            <span className="icon">&#10004;</span>
            Learn about, quote and buy the best coverage
          </li>
          <li className="success">
            <span className="icon">&#10004;</span>
            Liability insurance included with host liquor liability
          </li>
          <li className="success">
            {/* <span className="icon">✔️</span> */}
            <span className="icon">&#10004;</span>
            Cancellation insurance available to protect your investment
          </li>
          <li className="success">
            {/* <span className="icon">❌</span> */}
            <span className="icon">&#10004;</span>
            Guaranteed to meet your venues insurance requirements
          </li>
        </ul>

        <AskIndmenButton
          className="card-button"
          variant="outlined"
          endIcon={<FeatherIcon icon="message-circle" />}
          size="large"
        >
          Get Quote
        </AskIndmenButton>
      </div>

      {/* Full Liability Card */}
      <div className="two-column-card-box card-full-liability">
        <div className="recommended-badge">Venue Owners</div>
        <h3>Venues, Partners and Platforms</h3>
        <div className="card-price">Partner</div>
        <div className="card-onwards">Offer a modern insurance experience</div>

        <ul className="card-features">
          <li className="success">
            <span className="icon">&#10004;</span>A trusted source you can rely
            on
          </li>
          <li className="success">
            <span className="icon">&#10004;</span>
            Tools and resources to fit any platform or venue
          </li>
          <li className="success">
            <span className="icon">&#10004;</span>
            Dedicated professionals available to help 24/7
          </li>
          <li className="success">
            <span className="icon">&#10004;</span>
            Automate the insurance requirement for your business
          </li>
        </ul>

        {/* <AskIndmenButton
                    className="card-button"
                    variant="contained"
                    endIcon={<FeatherIcon icon="message-circle" />}
                    size="large"
                >
                    Partner with Us
                </AskIndmenButton> */}

        <Link to="/partner-with-us">
          <Button
            className="card-button-dark"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
          >
            Partner with Us
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TwoColumnCard;
