import React from 'react';
import { Box } from '@mui/material';

interface LogoProps {
  isDark?: boolean;
  width?: number;
}

export default function Logo({ isDark = false, width = 200 }: LogoProps) {
  return (
    <Box component="a" href="/" sx={{ textDecoration: 'none' }}>
      <Box
        component="img"
        src={isDark ? '/images/logo_dark.svg' : '/images/logo_eventguard.svg'}
        alt="Indemn"
        width={width}
      />
    </Box>
  );
}
