import React from 'react';
import { Button, Typography, Box, useTheme } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { Container, GridContainer, MarkDown } from 'components';

// dummy prices
interface Price {
  title: string;
  price: number;
  covers: string[];
  notCovers: string[];
  recommended?: boolean;
}

const prices: Price[] = [
  {
    title: 'Basic Liability only',
    price: 75,
    covers: [
      'Liability starting at $500k',
      'Covers injury to guests',
      'Liquor liability optional',
    ],
    notCovers: ['Doesn’t cover cancellation']
  },
  {
    title: 'Cancellation only',
    price: 100,
    covers: [
      'Cancellation coverage from $7,500',
      'Covers vendor no-show',
      'Reimbursement for non-refundable deposits',
    ],
    notCovers: ['Doesn’t cover cancellation']
  },
  {
    title: 'Full liability',
    price: 175,
    covers: [
      'Liability up to $2 million',
      'Covers cancellation to the event',
      'Covers injury to guests',
      'Comprehensive liability coverage'
    ],
    notCovers: [],
    recommended: true
  }
];

const PriceCard = ({ ...data }: Price): JSX.Element => {
  const { typography, palette } = useTheme();
  const { title, price, covers, notCovers, recommended } = data;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '20px 16px',
        borderRadius: '12px',
        background: recommended ? palette.primary[900] : palette.primary[50],
        border: `1px solid ${recommended ? palette.primary[500] : palette.primary[300]}`,
        gap: { xs: 2.5, md: 4.5 },
        height: 'fit-content'
      }}
    >
      <Box
        display='flex'
        gap={{ xs: 0, md: 1.5 }}
        justifyContent='space-between'
        flexDirection={{ xs: 'row', md: 'column' }}
        alignItems={{ xs: 'center', md: 'baseline' }}
      >
        <Typography color={recommended ? 'primary.50' : 'primary.800'} sx={{ ...typography.sm, ...typography.medium }}>{title}</Typography>
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          flexDirection={{ xs: 'column', md: 'row' }}
          p={{ xs: 0, md: 2 }}
        >
          <Typography color={recommended ? 'white' : 'monochrome.gray.800'} sx={{ ...typography['3xl'], ...typography.bold }}>${price}</Typography>
          <Typography color={recommended ? 'monochrome.gray.100' : 'monochrome.gray.500'} sx={{ ...typography.xs, ...typography.regular }}>onwards</Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        {covers.map((txt) => (
          <Box display="grid" gridTemplateColumns="16px 1fr" alignItems="center" gap={1} key={txt}>
            <FeatherIcon icon="check" size={16} color={palette.tertiary[500]} />
            <Typography color={recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'} sx={{ ...typography.xs, ...typography.medium }}>{txt}</Typography>
          </Box>
        ))}

        {notCovers.map((txt) => (
          <Box display="grid" gridTemplateColumns="16px 1fr" alignItems="center" gap={1} key={txt}>
            <FeatherIcon icon="x" size={16} color={palette.monochrome.gray[500]} />
            <Typography color={recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'} sx={{ ...typography.xs, ...typography.regular }}>{txt}</Typography>
          </Box>
        ))}
      </Box>

      <Box textAlign="right">
        <Button
          variant='contained'
          endIcon={<FeatherIcon icon="message-circle" size={20} />}
          size='medium'
          color={recommended ? 'primary' : 'secondary'}
          sx={{ width: { xs: 'fit-content', md: 1 } }}
        >
          Begin quote
        </Button>
      </Box>

      {recommended &&
        <Box position="absolute" top={-4} right={{ xs: 'unset', md: -4 }} left={{ xs: -4, md: 'unset' }} bgcolor={palette.tertiary[200]}>
          <Typography color='tertiary.900' sx={{ ...typography.xs, ...typography.regular }} padding="2px 6px">RECOMMENDED</Typography>
        </Box>
      }
    </Box>
  );
};

const Pricing = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer>
      <Box
        sx={{
          display: { xs: 'flex', xl: 'grid' },
          gridTemplateColumns: { xs: 'unset', xl: '1fr 3fr' },
          flexDirection: { xs: 'column', xl: 'unset' },
          gap: { xs: 1.5, xl: 0.5 }
        }}
      >
        <Container width={{ xs: 1, md: 0.5, xl: 1 }}>
          <MarkDown
            sx={{ ...typography['2xl'], ...typography.bold }}
            text='How much does *wedding* insurance cost?'
          />
          <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
            Wedding insurance is available to cover you from lawsuits arising out of the event, and to protect your substantial investments as the host.
          </Typography>
        </Container>
        <Container
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
            gap: { xs: 2.5, md: 4.5, xl: 6.5 }
          }}
        >
          {prices.map((price) => (
            <PriceCard {...price} key={price.title} />
          ))}
        </Container>
      </Box>
    </GridContainer>
  );
};

export default Pricing;
