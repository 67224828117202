import React from 'react';
import { GridContainer, Container, Image, CloudImage } from 'components';
import { Typography, Button, Box, useTheme } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

interface Product {
  icon: string;
  name: string;
  title: string;
  content: string[];
  href: string;
  img: string;
}

const products: Product[] = [
  {
    icon: '/images/products/acquisition.svg',
    name: 'Conversational Sales',
    title: 'GPT assistants for Insurance Sales',
    content: [
      'Purchase insurance through our AI-powered front end point of sale.',
      'Indemn can build incredible insurance experiences for your digital insurance catalogs.'
    ],
    href: '/conversational-sales',
    img: 'home-acquisition-img'
  },
  {
    icon: '/images/products/copilot.svg',
    name: 'Agent Copilot',
    title: 'Agent Copilot for Insurance Sales & Service',
    content: [
      'Rapidly deploy and address customer queries and smoothly shift to AI-assisted human agents.',
      'Elevate your distribution with advanced AI'
    ],
    href: '/agent-copilot',
    img: 'home-copilot-img'
  },
  {
    icon: '/images/products/distribute.svg',
    name: 'Distribute with Indemn',
    title: 'Distribute with Indemn',
    content: [
      'Strategic pieces for your Affinity & Embedded insurance programs',
      'Indemn can integrate with any digital partner.'
    ],
    href: '/distribution',
    img: 'distribute_splash_indemn-img'
  },
];

const Product = (product: Product): JSX.Element => {
  const { typography } = useTheme();
  const { icon, name, title, content, href, img } = product;

  return (
    <GridContainer>
      <Container display="flex" flexDirection="column" gap={2} width={{ xs: 1, md: 5 / 8, xl: 5 / 12 }}>
        <Box display='flex' alignItems='center' gap={2}>
          <Image
            src={icon}
            width={32}
            height={32}
            imageProps={{
              style: { objectFit: 'cover' }
            }}
          />
          <Typography color='primary.800' sx={{ ...typography.base, ...typography.semibold }}>
            {name}
          </Typography>
        </Box>
        <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          {title}
        </Typography>
        <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular }}>
          {content[0]}
          {content[1] ?
            <>
              <br />
              <br />
              {content[1]}
            </> : ''
          }
        </Typography>
        <Button
          variant='outlined'
          endIcon={<FeatherIcon icon="arrow-right" />}
          href={href}
          color='inherit'
          size='medium'
          sx={{ width: 'fit-content' }}
        >
          Discover our products
        </Button>
      </Container>
      <Container width={{ xs: 1, xl: 8 / 12 }} margin="0 auto" mt={{ xs: 4, md: 6 }}>
        <CloudImage id={img} />
      </Container>
    </GridContainer>
  );
};

const Products = (): JSX.Element => {
  return (
    <>
      {products.map(product => <Product key={product.name} {...product} />)}
    </>
  );
};

export default Products; 
