import React, { createContext, useEffect, useState } from "react";

// Define window type with WebChat property
interface CustomWindow extends Window {
  WebChat: {
    default: (config: any, element: any) => Promise<void>;
  };
}
declare const window: CustomWindow;

interface ChatOpenProps {
  isFirstUserInteraction: boolean;
}

export let chatOpen: (props?: ChatOpenProps) => void;
export let sendMessage: (message: string) => void;
export let sendPayload: (payload: string) => void;

interface ChatbotState {
  isChatOpen: boolean;
  isChatbotConnected: boolean;
  scriptLoaded: boolean;
}

interface ChatbotStoreProviderProps {
  botId?: string;
  children: React.ReactNode;
  initPayload?: string;
  customData?: any;
  subtitle?: string;
  embeded?: boolean;
  botType?: string;
  showSummary?: boolean;
}

const initialState: ChatbotState = {
  isChatOpen: false,
  isChatbotConnected: false,
  scriptLoaded: false,
};

export const ChatbotContext = createContext<{ chatbotState: ChatbotState }>({
  chatbotState: initialState,
});

export const ChatbotStoreProvider: React.FC<ChatbotStoreProviderProps> = ({
  children,
  initPayload = "/get_started",
  subtitle = "Wedding Events Chatbot",
  embeded,
  botType = "wedding",
  showSummary = true,
  customData = {},
  botId = process.env.REACT_APP_WEDDING_BOT || "65e18047b26fd2526e096cd0",
}) => {
  const [isChatOpen, setChatOpen] = useState(false);
  const [isChatbotConnected, setConnected] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const onWidgetEvent = {
    onChatOpen: () => setChatOpen(true),
    onChatClose: () => setChatOpen(false),
  };

  const onSocketEvent = {
    connect: () => {
      console.log("Connection established");
      setConnected(true);
    },
    disconnect: () => {
      console.log("Connection ended");
      setConnected(false);
    },
  };

  const chatOpenTrigger = (fn: (props?: ChatOpenProps) => void) => {
    chatOpen = fn;
  };

  const sendMessageTrigger = (fn: (message: string) => void) => {
    sendMessage = fn;
  };

  const sendPayloadTrigger = (fn: (payload: string) => void) => {
    sendPayload = fn;
  };
  const appendChatScript = (scriptUrl: string) => {
    const script = document.createElement("script");
    script.src = scriptUrl;
    script.async = true;

    script.onload = async () => {
      try {
        const config = {
          id: botId,
          initPayload,
          params: {
            storage: "session",
          },
          title: "Indemn",
          subtitle,
          showSummary,
          socketUrl: process.env.REACT_APP_SOCKET_MIDDLEWARE_URL,
          botType,
          socketPath: "/socket.io/",
          opsUrl: process.env.REACT_APP_OPS_API_HOST,
          embedded: embeded,
          onWidgetEvent,
          onSocketEvent,
          chatOpenTrigger,
          sendMessageTrigger,
          sendPayloadTrigger
        };

        if (Object.keys(customData).length > 0) {
          config["customData"] = customData;
        }

        await window.WebChat.default(config, null);
        setScriptLoaded(true);
      } catch (error) {
        console.error("Failed to initialize WebChat:", error);
      }
    };

    script.onerror = () => {
      console.error("Failed to load chat script");
    };

    document.body.appendChild(script);
  };

  const removeChatScript = (scriptUrl: string) => {
    const scripts = document.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i];
      const src = script.getAttribute("src");
      if (src && src.includes(scriptUrl)) {
        script.parentNode?.removeChild(script);
      }
    }
  };

  useEffect(() => {
    sessionStorage.clear(); // TODO: need a tactic to manage session.
    const src = process.env.REACT_APP_POS_SCRIPT_URL
      ? process.env.REACT_APP_POS_SCRIPT_URL
      : "https://s3.amazonaws.com/indemn.ai/pos-script/dev/index-dev.js";
    appendChatScript(src);
    return () => {
      removeChatScript(src);
    };
  }, []);

  return (
    <ChatbotContext.Provider
      value={{
        chatbotState: {
          isChatOpen,
          isChatbotConnected,
          scriptLoaded,
        },
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};
