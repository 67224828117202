import React from 'react';
import { GridContainer, Container } from 'components';
import Feature from 'views/components/feature';
import { Typography, useTheme, Box } from '@mui/material';

const values = [
  { title: 'Innovate for tomorrow', content: 'Our aim is not just to offer insurance, but to redefine its very experience.  We look to constantly evolve our efforts towards forward-thinking solutions.' },
  { title: 'Integrity in every interaction', content: 'We believe in transparency, ensuring our customers always have a clear understanding of what they\'re purchasing.' },
  { title: 'Thoughtful user experience', content: 'We\'ve harnessed modern tools to provide a dynamic, confident, and seamless user experience, that looks to save you time and energy.' }
];

const Values = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" gap={3}>
      <Container>
        <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          Values
        </Typography>
      </Container>
      <Box display="grid" gridTemplateColumns={{ xs: '1f', xl: '1fr 1fr 1fr' }} gap={{ xs: 1.5, xl: 0.5 }}>
        {values.map(feature => <Feature key={feature.title} {...feature} />)}
      </Box>
    </GridContainer>
  );
};

export default Values;
