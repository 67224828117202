import React, { useEffect, useState } from "react";
import { GridContainer } from "../components";
import { Box } from "@mui/material";
import AskIndmenButton from "../views/components/ask-indemn-button";
import FeatherIcon from "feather-icons-react";
import "./JumboCardAlt.css"; // Keep the general styles
import * as constants from "../utils/constants";

const JumboCardAlt = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update layout on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <GridContainer>
      <Box className="jumbo-card-extra-alt">
        <Box className="content-section-alt">
          <h2 className="header-text-alt">Your policy quality matters</h2>
          {/* Conditionally render the logo on mobile */}
          {isMobile && (
            <img
              src={constants.CLOUDINARY_IMAGES.MARKEL_LOGO}
              alt="Markel Logo"
              className="mobile-markel-logo"
            />
          )}
          <p className="body-text-alt">
            The quality of your event insurance coverage is our top priority,
            which is why we exclusively use a program that is underwritten by{" "}
            <strong>Markel</strong>, the nation's leader in event insurance for
            over 25 years.
          </p>
          <p className="body-text-alt mobile-hidden">
            Having peace of mind for your event is everyone's goal, and having
            this policy program on your side helps you achieve that more than
            anything else. This program covers everything from private events
            like weddings, birthdays, and showers, and even upwards to business
            meetings, corporate events, and non-profit functions.
          </p>
          <span className="italic-text">
            *Other policy programs available for the rare instance that your
            event is not eligible for the preferred Markel program that we
            offer.
          </span>{" "}
          <br />
          <AskIndmenButton
            variant="contained"
            className="action-button-alt"
            endIcon={<FeatherIcon icon="message-circle" />}
            size="large"
          >
            Get Coverage
          </AskIndmenButton>
        </Box>
        <Box
          className="logo-section-alt"
          style={{
            backgroundImage: `url(${constants.CLOUDINARY_IMAGES.MARKEL_GRADIENT})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "40%",
            height: "100%",
          }}
        ></Box>
      </Box>
    </GridContainer>
  );
};

export default JumboCardAlt;
