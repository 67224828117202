import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Image } from "components";
import { useState, useEffect } from "react";

interface Props {
  items: any[];
}

const ProgressCarousel = ({ items }: Props) => {
  const { typography, breakpoints } = useTheme();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const isMd = useMediaQuery(breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress((prev) => prev + 1);
      } else {
        setProgress(0);
        setCurrentIndex((prev) => (prev + 1) % items.length);
      }
    }, 30); // Adjusted for smoother progress

    return () => clearInterval(timer);
  }, [progress]);

  const iconWidth = isMd ? 260 : 80;
  const iconSize = {
    large: {
      width: 1.5 * iconWidth,
      height: 1.5 * iconWidth,
    },
    medium: {},
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {items.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: 60, md: 120 },
              height: 3,
              backgroundColor: "#B5C2ED",
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                height: 3,
                backgroundColor: "#1E40AF",
                borderRadius: 4,
                width: `${index === currentIndex ? progress : 0}%`,
              }}
            />
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: { xs: 1, xl: 3 / 4 },
          transition: "opacity 200ms ease-in-out",
          opacity: progress > 90 ? 0 : 1,
          margin: "0 auto",
          mt: 6,
        }}
      >
        {isMd ? (
          <Box
            display="grid"
            gridTemplateColumns="max-content 1fr"
            gap={6}
            alignItems="flex-end"
          >
            <Image
              src={items[currentIndex].image}
              sx={{
                width: iconWidth,
                height: iconWidth,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              imageProps={{
                style: {
                  objectFit: "contain",
                  mixBlendMode: "darken",
                  ...iconSize[items[currentIndex].size],
                },
              }}
            />
            <Stack gap={3} alignItems="flex-end" mb={1}>
              <Typography
                color="primary.800"
                sx={{ ...typography.xl, ...typography.regular }}
              >
                {items[currentIndex].recommendation}
              </Typography>
              <Box display="flex" flexDirection="column">
                <Typography
                  color="primary.800"
                  sx={{
                    ...typography.lg,
                    ...typography.bold,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {items[currentIndex].name}
                </Typography>
                <Typography
                  color="primary.800"
                  sx={{ ...typography.lg, ...typography.regular }}
                >
                  {items[currentIndex].role}
                </Typography>
              </Box>
            </Stack>
          </Box>
        ) : (
          <Stack gap={3} alignItems="flex-end">
            <Typography
              color="primary.800"
              sx={{ ...typography.xl, ...typography.regular }}
            >
              {items[currentIndex].recommendation}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="max-content 1fr"
              gap={2}
              alignItems="flex-end"
            >
              <Image
                src={items[currentIndex].image}
                sx={{
                  width: iconWidth,
                  height: iconWidth,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                imageProps={{
                  style: {
                    objectFit: "contain",
                    mixBlendMode: "darken",
                    ...iconSize[items[currentIndex].size],
                  },
                }}
              />
              <Stack gap={1}>
                <Typography
                  color="primary.800"
                  sx={{
                    ...typography.lg,
                    ...typography.bold,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {items[currentIndex].name}
                </Typography>
                <Typography
                  color="primary.800"
                  sx={{ ...typography.lg, ...typography.regular }}
                >
                  {items[currentIndex].role}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default ProgressCarousel;
