import React from "react";
import { Typography, useTheme } from "@mui/material";
import Main from "layouts/main";
import { Image, GridContainer, Container, MarkDown, Square } from "components";
import * as constants from "utils/constants";

const NotFound = (): JSX.Element => {
  const { typography, shadows } = useTheme();

  return (
    <Main>
      {/* hero background */}
      <Image
        src={constants.CLOUDINARY_IMAGES.PAGE_NOT_FOUND_HERO_IMAGE}
        alt="Page not found"
        sx={{
          position: "absolute",
          height: "100%",
          width: 1,
          top: 0,
        }}
        imageProps={{
          style: {
            opacity: 0.24,
            objectFit: "contain",
            mixBlendMode: "darken",
          },
        }}
      />
      <GridContainer
        pt={20}
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="center"
      >
        <Container width={{ xs: 1, xl: 8 / 12 }}>
          <Typography
            color="primary.900"
            sx={{ ...typography["4xl"], ...typography.bold }}
            textAlign="center"
          >
            Error (404) - Wave not found
          </Typography>
        </Container>
        <Square width={{ xs: 1, md: 4 / 8, xl: 4 / 12 }} maxWidth={424}>
          <Image
            src={constants.CLOUDINARY_IMAGES.PAGE_NOT_FOUND_HERO_IMAGE}
            alt="Page not found"
            width={1}
            height={1}
            imageProps={{
              style: {
                objectFit: "cover",
                borderRadius: 12,
                boxShadow: shadows[1],
              },
            }}
          />
        </Square>
        <Container width={{ xs: 1, xl: 8 / 12 }}>
          <MarkDown
            sx={{ ...typography.xl, ...typography.medium }}
            textAlign="center"
            text={`The tide’s out and so is this page. Like our puzzled surfer,
            \nwe’re missing the wave you’re after. Time to ride back to the [homepage](/).`}
          />
        </Container>
      </GridContainer>
    </Main>
  );
};

export default NotFound;
