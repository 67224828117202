import React, { useState, useContext, useMemo } from "react";
import {
  Box,
  AppBar,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import { Logo, GridContainer, Container, MarkDown, Image } from "components";
import mixpanel from "mixpanel-browser";
import FeatherIcon from "feather-icons-react";
import { PageContext } from "context/page-state";
import AskIndmenButton from "views/components/ask-indemn-button";
import NavMenu from "layouts/components/nav-menu";
import NavCollapse from "layouts/components/nav-collapse";
import { parseNavItem } from "utils/helper-generic";
mixpanel.init("f8fc6cf912ff3cdf25b9af5ce7ed3dd9", { debug: true });

const Topbar = (): JSX.Element => {
  const { typography, palette, breakpoints } = useTheme();

  const pageCtx = useContext(PageContext);
  const { events } = pageCtx.pageState;

  const [openNavigationMenu, setOpenNavigationMenu] = useState<boolean>(false);

  const isXl = useMediaQuery(breakpoints.up("xl"), {
    defaultMatches: true,
  });

  const handleOpenNavigationMenu = () => {
    setOpenNavigationMenu((pre) => !pre);
  };

  const insuranceList = useMemo(
    () =>
      events.map(({ slug, list_title }) => {
        const { text, icon, tag } = parseNavItem(list_title);

        return (
          <Box display="flex" gap={1.5} alignItems="center" key={slug}>
            <Button
              variant="text"
              size="small"
              endIcon={<FeatherIcon icon={icon} />}
              href={`/get-insurance/${slug}`}
            >
              <MarkDown
                sx={{ ...typography.sm, ...typography.medium }}
                text={text}
              />
            </Button>
            {!!tag && (
              <Box
                display="flex"
                gap={0.5}
                alignItems="center"
                padding="4px 6px"
              >
                <Image
                  src={`/images/icons/${tag}.svg`}
                  width={12}
                  height={12}
                  display="flex"
                  imageProps={{
                    style: { objectFit: "cover" },
                  }}
                />
                <Typography
                  color="secondary.500"
                  sx={{ ...typography.xs, ...typography.light }}
                >
                  Most {tag}
                </Typography>
              </Box>
            )}
          </Box>
        );
      }),
    [events]
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        top: { xs: 16, md: 32 },
        background: "transparent",
        boxShadow: "none",
      }}
    >
      <GridContainer pb={0}>
        <Container
          sx={{
            width: 1,
            background: openNavigationMenu
              ? palette.background.navMenu
              : palette.background.header,
            float: "right",
            borderRadius: "12px",
            border: "1px solid rgba(255, 255, 255, 0.60)",
            backdropFilter: "blur(16px)",
            transition: "all .2s ease-in-out",
            display: "flex",
            flexDirection: "column",
            gap: 6,
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", xl: "grid" },
              gridTemplateColumns: {
                xs: "unset",
                xl: "max-content 1fr max-content",
              },
              alignItems: "center",
              justifyContent: { xs: "space-between", xl: "unset" },
            }}
          >
            <Logo />
            <Box
              sx={{
                display: { xs: "none", xl: "flex" },
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
              }}
            >
              <NavMenu label="Get insurance">{insuranceList}</NavMenu>
              <Button
                variant="text"
                size="medium"
                href="/partner-with-us"
                sx={{ ...typography.sm }}
              >
                Partner with us
              </Button>
              <Button
                variant="text"
                size="medium"
                href="/about"
                sx={{ ...typography.sm }}
              >
                About
              </Button>
            </Box>
            {isXl ? (
              <AskIndmenButton
                variant="contained"
                endIcon={<FeatherIcon icon="message-circle" />}
                size="medium"
                payload="/faqs__list"
              >
                Ask EventGuard
              </AskIndmenButton>
            ) : (
              <IconButton sx={{ p: 0.5 }} onClick={handleOpenNavigationMenu}>
                <FeatherIcon
                  icon={openNavigationMenu ? "x" : "menu"}
                  color={palette.primary[800]}
                />
              </IconButton>
            )}
          </Box>

          {openNavigationMenu && !isXl && (
            <>
              <Box
                sx={{
                  maxHeight: "calc(100vh - 240px)",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  alignItems: "baseline",
                  padding: 0.5,
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  alignItems="baseline"
                >
                  {/* <NavCollapse label="Products" pl={0}>
                    {productList}
                  </NavCollapse> */}
                  <NavCollapse label="Get insurance">
                    {insuranceList}
                  </NavCollapse>
                </Box>
                <Button
                  variant="text"
                  size="medium"
                  href="/partner-with-us"
                  sx={{ ...typography.sm }}
                >
                  Partner with us
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  href="/about"
                  sx={{ ...typography.sm }}
                >
                  About
                </Button>
              </Box>

              <AskIndmenButton
                variant="contained"
                endIcon={<FeatherIcon icon="message-circle" />}
                size="medium"
                payload="/faqs__list"
              >
                Ask EventGuard
              </AskIndmenButton>
            </>
          )}
        </Container>
      </GridContainer>
    </AppBar>
  );
};

export default Topbar;
