import React from "react";
import { GridContainer, Container, Image } from "components";
import { useTheme, Typography, Box } from "@mui/material";
import * as constants from "utils/constants";

const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <div style={{ position: "relative" }}>
      {" "}
      {/* Positioned container */}
      <GridContainer pt={20} pb={0}>
        <Container display="flex" flexDirection="column" gap={2}>
          <Typography
            color="primary.900"
            sx={{ ...typography.sm, ...typography.regular }}
          >
            About Eventguard
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: "1fr", xl: "1fr 1fr" }}
            gap={6.5}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography["2xl"], ...typography.semibold }}
            >
              We’re on a mission to make event insurance easy to provide to
              everyone.
            </Typography>
            <Typography
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              EventGuard is a registered Insurance agent and MGA underwritten by
              our insurance partner, Markel.
              <br />
              <br />
              We empower you with an AI-powered experience that can answer
              questions, generate customizable quotes, and execute service
              requests instantly.
              <br />
              <br />
              We work with hundreds of partners across the USA to provide risk
              products to their event customers.
              <br />
              <br />
              EventGuard has the unique history of being the first ever
              insurance program that was sold through GPT based AI, built and
              launched by Indemn in 2021-22 on GPT-3.
              <br />
              <br />
              We are committed to providing our venue partner network with
              solutions for event insurance in the USA 🇺🇸
            </Typography>
          </Box>
        </Container>
      </GridContainer>
      {/* Hero Background */}
      <div>
        <Image
          src={constants.CLOUDINARY_IMAGES.ABOUT_HERO_IMAGE}
          alt="about-hero-image"
          sx={{ my: 8 }}
          imageProps={{
            style: {
              opacity: 0.64,
              objectFit: "cover",
              mixBlendMode: "multiply",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Hero;
